import { DomainContext } from '@sai/protocol';
import ImageUtils from './ImageUtils';

class ServerUtils {
    private static _instance: ServerUtils;

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public sendFile(srcId, file, url, onProgress): Promise<any> {
        var formData = new FormData();
        formData.append('device', 'web');
        return new Promise((accept, reject) => {
            ImageUtils.resizeImage(file, 2048000)
                .then((fileResult) => {
                    formData.append('file', fileResult, fileResult.name);
                    $.ajax({
                        url: url,
                        type: 'POST',
                        xhr: function () {  // Custom XMLHttpRequest
                            var myXhr = $.ajaxSettings.xhr();
                            if (myXhr.upload) { // Check if upload property exists
                                myXhr.upload.addEventListener('progress', onProgress.bind(srcId), false); // For handling the progress of the upload
                            }
                            return myXhr;
                        },
                        statusCode: {
                            500: reject,
                            502: reject,
                            401: reject
                        },
                        //Ajax events
                        success: accept,
        
                        // Form data
                        data: formData,
                        //Options to tell jQuery not to process data or worry about content-type.
                        cache: false,
                        contentType: false,
                        processData: false,
                        dataType: 'json'
                    });
                })
                .catch(reject);

        });
    }

    public getDomainContext(domain: string) {
        return new DomainContext(domain, $('html').data('timestamp'));
    }

    private readFile(file): Promise<string|ArrayBuffer> {
        return new Promise((accept, reject) => {
            const filereader = new FileReader();
            filereader.onload = function() {
                accept(filereader.result);
            }
            filereader.readAsDataURL(file);
        });
    }
}

const singleInstance = ServerUtils.Instance;
export default singleInstance;