module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<div class="parent-container">\n    <div class="extra left">\n    </div>\n    <div class="enumIconList">\n        ';
 var allVals = value == null ? [] : (typeof value !== 'string' ? value : value.split(',')); ;
__p += '\n        ';
 if(allowEmptyValue) { ;
__p += '\n        <div class="enumIcon">\n            <div class="icon fa fa-ban"></div>\n            <div class="label">&nbsp;</div>\n        </div>\n        ';
 } ;
__p += '\n        ';
 for(var i = 0; i < selectValues.length; i++) { ;
__p += '\n            ';
 var curVal = selectValues[i].getId(); ;
__p += '\n            ';
 if( curVal === value || allVals.includes(curVal)) { ;
__p += '\n                <div data-id="' +
__e( selectValues[i].getId() ) +
'" class="enumIcon selected">\n            ';
 } else { ;
__p += '\n                <div data-id="' +
__e( selectValues[i].getId() ) +
'" class="enumIcon">\n            ';
 } ;
__p += '\n                    <div class="icon" />\n                    <div class="label">' +
__e( selectValues[i].getLabel()) +
'</div>\n                </div>\n        ';
 } ;
__p += ' \n    </div>\n    <div class="extra right">\n    </div>\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}