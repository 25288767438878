'use strict';

const { CClientConfiguration, Filter } = require('@sai/configuration');
const { Task, Context, Server } = require('@sai/protocol');

import ScriptUtils from '../../../utils/ScriptUtils';
import { Header, Column } from '../../../listing/Header';
import DropDownField from './DropDownField';

let $ = require('jquery');

class CoupleField extends DropDownField {
    private currentPage: number;
    private previousQuery: string;
    private requestHeaders: any;
    private resultOptions: Array<{ key: string, value: string}>;
    private associatedField: CoupleField;
    private displayedLabel: string;
    private nbElements:number;
    private currentCallPage:number;
    protected associatedLabels: { [key: string] : string};

    constructor(options) {
        super(options);
        this.resultOptions = [];
        this.nbElements = 15;
        this.associatedLabels = {};
    }

    public isMultiple(): boolean {
        return false;
    }

    public setAssociatedField(associated: CoupleField): void {
        this.associatedField = associated;
    }

    render():any {
        super.render();
        if(!this.isMultiple()) {
            let linkedCouple = this.fieldConfig.getLinkedCouple();
            if(linkedCouple.isFusion() && linkedCouple.getLabelField().getDatafieldId() === this.fieldConfig.getDatafieldId()) {
                this.$el.hide();
            }
        }
    }

    public onBeforeFieldChange(fromServer: boolean): void {
        //Update of the associate couple field
        var selectEl:any = this.$el.find('.selectpicker');
        // If the value is changed from the server, do not modified the associatedField
        // else the value is set by the user from the UI so we can modify the associatedField
        if(selectEl.select2('data') && !fromServer && !this.isMultiple()) {
            let item = selectEl.select2('data')[0];
            let associatedValue = '';
            if(item.data) {
                let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
                let coupleKey = this.fieldConfig.getCoupleKey();
                let coupleLabel = this.fieldConfig.getLinkedCouple().getLabelField().getDatafieldId();
                let friendColumn = this.fieldConfig.isCoupleKey() ? datafieldToColumnId[coupleLabel] : datafieldToColumnId[coupleKey];
                associatedValue = this.associatedField.parseValue(item.data.values.columns[friendColumn].value);
            }
            this.associatedField.setModelValue(associatedValue);
            this.associatedField.render();
            this.associatedField.renderPosition();
            this.associatedField.onDOMUpdated();
        }
    }

    onDOMUpdated () {
        super.onDOMUpdated();
        var me = this;
        var selectEl:any = this.$el.find('.selectpicker');
        me.currentPage = 0;
        selectEl.select2({
            ajax: {
                transport: this.callGetGridData.bind(me),
                delay: 250,
                processResults: this.processResults.bind(me)
            },
            minimumInputLength: 0,
            templateResult: me.buildFromTemplate.bind(me),
            templateSelection: me.selectItem.bind(me),
            dropdownAutoWidth: true,
            width: '100%',
            allowClear: true,
            placeholder: '',
        });

        selectEl.on('select2:unselecting', function(ev) {
            if (ev.params.args.originalEvent) {
                // When unselecting (in multiple mode)
                ev.params.args.originalEvent.stopPropagation();
            } else {
                // When clearing (in single mode)
                $(this).one('select2:opening', function(ev) { ev.preventDefault(); });
            }
        });

        selectEl.on('select2:opening', this.checkForSelectBlocked.bind(this, selectEl));
        selectEl.on('select2:opening', () => {
            this.$el.find('label').addClass('active');
        });
        selectEl.on('select2:close', () => {
            if (this.getValue() === '') {
                this.$el.find('label').removeClass('active');
            }
        });

        // Reset of the page selection
        selectEl.on('select2:open', function (e) {
            me.currentPage = 0;
        });

        var displayMode = this.displayMode;
        // If the field width is 0, it should be hidden together with the select2 container
        let fieldWidth = this.getState().getPosition(this.displayMode).width;
        if(fieldWidth === '0%' || fieldWidth === '0') {
            this.$el.find('select').hide();
            this.$el.find('.select2-container').hide();
        } else {
            this.$el.find('.select2-container').css({
                width: '100%'
            });
        }

        if(!this.isMultiple()) {
            let linkedCouple = this.fieldConfig.getLinkedCouple();
            if(linkedCouple.isFusion() && linkedCouple.getKeyField().getDatafieldId() === this.fieldConfig.getDatafieldId()) {
                this.displayedLabel = this.getLinkedLabel();
                this.$el.find('.select2-selection__rendered').text(this.displayedLabel);
            }
        }

        if (this.getValue() !== undefined && this.getValue() !== '') {
            //There is a value, we need to activate the label
            this.$el.find('label').addClass('active');
        }
    }

    buildFromTemplate (item: any) {
        if (item.loading) {
            return item.text;
        } else {
            if (item.data) {
                var result = $('<div class="row coupleEntry">' + this.templateCouple(item.data, this.requestHeaders) + '</div>');
                result.find('.coupleiconPart').css({
                    padding: '0px',
                    width: '36px'
                });
                return result;
            } else {
                return '';
            }
        }
    }

    templateCouple(colVals: any, header: any){
        var htmlData = '';
        var firstDone = false;
        let columns = colVals.values.columns;
        let headers = header.getColumns();
        htmlData += '<div class="text"><div class="title">';
        for (var key in headers) {
            var currentHeader: Column = headers[key];
            if (! currentHeader.isHidden()) {
                var data = columns[currentHeader.getId()];
                if (data && data.value) {
                    if (firstDone && currentHeader.getColumnSeparator()) {
                        htmlData += '<span class="header-colSeparator">' + currentHeader.getColumnSeparator() + '</span>';
                    }else{
                        htmlData += '<span class="header-colSeparator">&nbsp;</span>';
                    }

                    if (data.humanValue && data.humanValue !== '') {
                        htmlData += '<span class="header-value">' + data.humanValue + '</span>';
                    } else {
                        htmlData += '<span class="header-value">' + data.value + '</span>';
                    }
                    firstDone = true;
                }
            }
        }
        htmlData += '</div></div>';
        //We do not display the icon in couples as the field is too small.. we could want to tough
        //htmlData += '<div class="icon"><img class="mainIcon coverIcon" src="'+Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big,record/128,100,64,36/' + colVals.values.icon)+'"/></div>';
        return htmlData;
    }

    selectItem (item: any) {
        if(item.data && !this.isMultiple()) {
            let linkedCouple = this.fieldConfig.getLinkedCouple();
            //On item selection from select2 input, we need to set the other couple field
            let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
            let coupleKey = this.fieldConfig.getCoupleKey();
            let coupleLabel = linkedCouple.getLabelField().getDatafieldId();

            let columnToUse, friendFieldColumn;
            let keyValue, labelValue;
            if(this.fieldConfig.isCoupleKey()) {
                columnToUse = datafieldToColumnId[coupleKey];
                friendFieldColumn = datafieldToColumnId[coupleLabel];
                keyValue = item.data.values.columns[columnToUse].value;
                labelValue = item.data.values.columns[friendFieldColumn].value;
            } else {
                columnToUse = datafieldToColumnId[coupleLabel];
                friendFieldColumn = datafieldToColumnId[coupleKey];
                keyValue = item.data.values.columns[friendFieldColumn].value;
                labelValue = item.data.values.columns[columnToUse].value;
            }

            //The displayed value depends of if the couple is just a field
            //or a combinaison of multiples (fusion)
            if(linkedCouple.isFusion()) {
                if(linkedCouple.isSwitched()) {
                    this.displayedLabel = labelValue + ' ' + keyValue;
                    return this.displayedLabel;
                } else {
                    this.displayedLabel = keyValue + ' ' + labelValue;
                    return this.displayedLabel;
                }
            } else {
                return item.data.values.columns[columnToUse].value;
            }
        } else if (item.data && this.isMultiple()) {
            let displayColumn = this.fieldConfig.getDisplayColumnId();
            return item.data.values.columns[displayColumn].value;
        } else {
            //We get straight value at initialisation
            return item.text;
        }
    }

    callGetGridData (params, success, failure) {
        if (params.data.q !== this.previousQuery) {
            this.currentPage = 0;
        }
        this.currentCallPage = this.currentPage;

        let grid = this.isMultiple() ? this.fieldConfig.getLinkedDataField().getGridView() : this.fieldConfig.getCoupleGrid();
        let dataRequest = new Task.GetGridDataRequest(this.domainContext, this.fieldConfig.getTask().getId(), grid);
        dataRequest.setFilterId(this.isMultiple() ? this.fieldConfig.getLinkedDataField().getFilterName() : this.fieldConfig.getCoupleFilter());
        dataRequest.setRange((this.currentPage * this.nbElements),((this.currentPage + 1) * this.nbElements) + 1);

        let filterContext = new Context({
            contextId: 'filter'
        });
        filterContext.addParameter('customText', params.data.q);
        if(params.data.q){
            dataRequest.setSessionVar('CUSTOMIZED_FILTER', true);
        }

        dataRequest.setFilterContext(filterContext);
        let requestContext = new Context({});
        this.trigger('panel.onPrepareContext', requestContext, this.fieldConfig.canSendAllPanels());
        if(requestContext.getContextId() === 'page'){
            dataRequest.setPageContext(requestContext);
        }else{
            dataRequest.setRecordContext(requestContext);
        }

        let ressources = []
        let filter = this.isMultiple() ? this.fieldConfig.getLinkedDataField().getFilterName() : this.fieldConfig.getCoupleFilter();
        if (filter !== 'DEFAULT_FILTER') {
            ressources.push(CClientConfiguration.getFilter(filter, this.domainContext));
        }

        let me = this;
        return Promise.all(ressources)
            .then((res) => {
                if(res.length === 1) {
                    //We've a specific filter, we check for values
                    let filter: typeof Filter = res[0];
                    let screenDfCache = {};
                    this.trigger('panel.onPrepareScriptContext', screenDfCache, this.fieldConfig.canSendAllPanels());
                    let filterEntries = filter.getElements();
                    let finalFilters = [];
                    for(let key in filterEntries) {
                        let filterEntry = filterEntries[key];
                        let finalValue;
                        let permanent = filterEntry.getPermanentValue();
                        let defaultVal = filterEntry.getDefaultValue();
                        if(permanent) {
                            if(permanent.includes('$')) {
                                //we need scripts
                                permanent = ScriptUtils.evalInContext(permanent, screenDfCache);
                            }
                            finalValue = permanent;
                        } else if(defaultVal) {
                            if(defaultVal.includes('$')) {
                                //we need scripts
                                defaultVal = ScriptUtils.evalInContext(defaultVal, screenDfCache);
                            }
                            finalValue = defaultVal;
                        }
                        if(finalValue) {
                            finalFilters.push({
                                datafieldId: filterEntry.getDatafieldId(),
                                value: finalValue
                            })
                        }
                    }
                    if(finalFilters.length > 0) {
                        filterContext.addFields(finalFilters);
                    }
                }
                Server.performRequest(dataRequest)
                .then(success)
                .catch((error) => {
                    failure(error || {});
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    callGetGridDataWithCustomFilter(customFilter) {
        this.currentPage = 0;
        this.currentCallPage = this.currentPage;
        let grid = this.isMultiple() ? this.fieldConfig.getLinkedDataField().getGridView() : this.fieldConfig.getCoupleGrid();
        let dataRequest = new Task.GetGridDataRequest(this.domainContext, this.fieldConfig.getTask().getId(), grid);
        dataRequest.setFilterId(this.isMultiple() ? this.fieldConfig.getLinkedDataField().getFilterName() : this.fieldConfig.getCoupleFilter());
        dataRequest.setRange((this.currentPage * this.nbElements), ((this.currentPage + 1) * this.nbElements) + 1);

        let filterContext = new Context({
            contextId: 'filter'
        });
        filterContext.addParameter('customText', customFilter);
        dataRequest.setSessionVar('CUSTOMIZED_FILTER', true);

        dataRequest.setFilterContext(filterContext);
        let requestContext = new Context();
        this.trigger('panel.onPrepareContext', requestContext, this.fieldConfig.canSendAllPanels());
        if(requestContext.getContextId() === 'page'){
            dataRequest.setPageContext(requestContext);
        }else{
            dataRequest.setRecordContext(requestContext);
        }

        return Server.performRequest(dataRequest);
    }

    onCoupleItemselected (itemId: any) {
        throw new Error('Not implemented yet with configuration');
    }

    getSelectedOption (selectedValue: any) {
        throw new Error('Not implemented yet with configuration');
    }

    processResults(data: any) {
        /*
         * Registering headers if not set yet
         */
        if(this.requestHeaders === undefined){
            this.requestHeaders = new Header(data.headers);
        }

        var items = [];
        //Empty result if no result
        data.items = data.items || [];

        /*
         *  Formatting of the result array. All items of
         *  select2 must be at least in the following format
         *
         *  {
         *    id: ''
         *    text: ''
         *  }
         */
        let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
        let coupleKey = this.isMultiple() ? this.requestHeaders.columns[0].config.datafieldId : this.fieldConfig.getCoupleKey();
        //In order to get the label, we need to look at the second field of the couple
        let coupleLabel = this.isMultiple() ? this.fieldConfig.getDisplayColumnId() : this.fieldConfig.getLinkedCouple().getLabelField().getDatafieldId();
        let columnToUse = this.isMultiple() ? (coupleLabel ? coupleLabel : coupleKey) : (this.fieldConfig.isCoupleKey() ? coupleKey : coupleLabel);
        let maxItems = Math.min(data.items.length,this.nbElements);
        for (var i = 0; i < maxItems; i++) {
            //We add an extra parameter that will be
            //stored as 'data' in the item. This data element
            //contains every column values of the items
            var valueData = {
                itemId: this.currentPage * this.nbElements + i,
                values: data.items[i]
            };

            items.push({
                id: data.items[i].columns[datafieldToColumnId[this.isMultiple() ? coupleKey : columnToUse]].value,
                text: '',
                data: valueData
            });

            this.resultOptions.push({
                key: valueData.values.columns[datafieldToColumnId[coupleKey]].value,
                value: valueData.values.columns[this.isMultiple() ? coupleLabel : datafieldToColumnId[coupleLabel]].value
            });
            this.associatedLabels[valueData.values.columns[datafieldToColumnId[coupleKey]].value] = valueData.values.columns[this.isMultiple() ? coupleLabel : datafieldToColumnId[coupleLabel]].value;
        }

        /*
         * The load more parameter tells the plugin that it
         * has to add a load more element at the end of the
         * item list and when the user scrolls to it, the
         * plugin loads the next page of data
         */
        var loadMore = data.items.length > this.nbElements;
        var result = {
            results: items,
            pagination: {
                more: loadMore
            }
        };

        /*
         * If we're on the first page we've to add an empty
         * element so that the user can clear the combo
         */
        if (this.currentPage === 0) {
            result.results.unshift({
                id: '',
                text: ''
            });
        }

        /*
         * Next call will ask for next page
         */
        if(this.currentPage === this.currentCallPage) {
            this.currentPage = this.currentPage + 1;
        }

        return result;
    }

    private getLinkedLabel() {
        if(this.isMultiple()) {
            return this.fieldState.getValue();;
        } else {
            let linkedCouple = this.fieldConfig.getLinkedCouple();
            //On item selection from select2 input, we need to set the other couple field
            //let datafieldToColumnId = this.requestHeaders.getDatafieldsToColumnIds();
            let coupleKey = this.fieldConfig.getCoupleKey();
            let coupleLabel = linkedCouple.getLabelField().getDatafieldId();
            let columnToUse, friendFieldColumn;
            let keyValue, labelValue;
            if(this.fieldConfig.isCoupleKey()) {
                //columnToUse = datafieldToColumnId[coupleKey];
                //friendFieldColumn = datafieldToColumnId[coupleLabel];
                keyValue = this.fieldState.getValue();
                labelValue = this.associatedField.getValue();
            } else {
                //columnToUse = datafieldToColumnId[coupleLabel];
                //friendFieldColumn = datafieldToColumnId[coupleKey];
                keyValue = this.associatedField.getValue();
                labelValue = this.fieldState.getValue();
            }

            if(linkedCouple.isSwitched()) {
                return labelValue + ' ' + keyValue;
            } else {
                return keyValue + ' ' + labelValue;
            }
        }
    }

    public focus() {
        let selectEl:any = this.$el.find('.selectpicker');
        selectEl.focus();
    }


    getValue() {
        let defaultValue = super.getValue();
        if(this.isMultiple()) {
            return typeof defaultValue === 'object'? defaultValue.join(',') : defaultValue;
        } else if(this.fieldConfig.isCoupleKey()) {
            return defaultValue;
        } else {
            let regexp = /([A-Z]{2})={(.*?)}/g
            let matches = [];
            let intLabelMap = {};
            while((matches = regexp.exec(defaultValue)) !== null) {
                intLabelMap[matches[1]] = matches[2];
            }
            let localeLabel = intLabelMap['FR']; //intLabelMap[User.getLocale().toUpperCase()];
            localeLabel = localeLabel === undefined ? intLabelMap['FR'] : localeLabel;
            if(localeLabel !== undefined) {
                return localeLabel;
            } else {
                return defaultValue;
            }
        }
    }
}

export default CoupleField;
