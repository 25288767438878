'use strict';

import FieldView from '../Field';

import 'select2';
import DropDownField from './DropDownField';
const { ComboElement, Combo } = require('@sai/configuration');

class EnumField extends DropDownField {

    onDOMUpdated () {
        super.onDOMUpdated();

        var selectEl:any = this.$el.find('.selectpicker');
        selectEl.select2(this.getSelectOptions());

        selectEl.on('select2:opening', this.checkForSelectBlocked.bind(this, selectEl));
        selectEl.on('select2:opening', () => {
            this.$el.find('label').addClass('active');
        });
        selectEl.on('select2:close', () => {
            if(this.getValue() === '') {
                this.$el.find('label').removeClass('active');
            }
        });

        var displayMode = this.displayMode;
        this.$el.find('.select2-container').css({
            width: '100%'
        });

        if(this.getValue() !== undefined && this.getValue() !== '') {
            //There is a value, we need to activate the label
            this.$el.find('label').addClass('active');
        }
    }

    getSelectOptions (){
        return {
            closeOnSelect: this.getCloseOnSelect()
        };
    }

    getCloseOnSelect (){
        return true;
    }

    getSelectedOption (selectedValue): typeof ComboElement {
        var selectedValues:Array<typeof ComboElement> = this.fieldConfig.getLinkedCombo().getElements();
        for(var key in selectedValues) {
            let currentEl = selectedValues[key];
            if(currentEl.getId() === selectedValue) {
                return currentEl;
            }
        }
        return null;
    }

    protected getRenderingModel(): {[key:string]: any} {
        let data = super.getRenderingModel();
        let combo: typeof Combo = this.fieldConfig.getLinkedCombo();
        if(!combo) {
            console.error('Missing linked combo on field ' + this.fieldConfig.getId() + ' in task ' + this.fieldConfig.getTask().getId());
        }
        data.selectValues = combo? combo.getElements() : [];
        data.multiple = this.canSelectMultiple();
        return data;
    }

    protected canSelectMultiple(): boolean {
        return false;
    }

    setModelValue (value) {
        super.setModelValue(value);
        var option: typeof ComboElement = this.getSelectedOption(value);
    }

    public focus() {
        let selectEl:any = this.$el.find('.selectpicker');
        selectEl.focus();
    }
}

export default EnumField;
