'use strict';

import FieldView from '../Field';
import I18NUtils from '../../../utils/I18NUtils';
import ErrorUtils from '../../../utils/ErrorUtils';

export default class File extends FieldView {
    protected uploadedFileList: Array<any> = [];
    protected lang: string;

    constructor(options) {
        super(options);
        this.lang = $('html').attr('lang').toUpperCase() || 'FR';
    }

    render() : any {
        super.render();
        this.$el.find('input').off('change.fileupload');
        this.$el.find('input').on('change.fileupload', this.autoUploadFiles.bind(this));

        this.$el.css('height', 'unset');
        this.$el.css('margin-bottom', '10px');
        this.$el.find('.uploaded-file-item').on('click', 'i', this.deleteFile.bind(this));
    }

    protected getRenderingModel(): {[key:string]: any} {
        let data = super.getRenderingModel();
        data.uploadedFiles = this.uploadedFileList;
        data.uploadLabel = I18NUtils.getText('upload_button_label', this.lang, {});
        return data;
    }

    autoUploadFiles() {
        this.$el.find('.field-loading').show();
        this.uploadFiles(this.fileUploadCallback)
            .then(() => {
                this.render();
            })
            .catch((err) => {
                this.displayError(err);
            })
            .finally(() => {
                this.$el.find('.field-loading').hide();
            });
    }

    displayUploadButton() {
        this.$el.find('.upload-button').show();
    }

    displayError(err) {
        let errorEl = this.$el.find('.upload-error');
        errorEl.html(ErrorUtils.getErrorDescription(err));
        errorEl.show();
    }

    deleteFile(event) {
        let id = $(event.delegateTarget).attr('id');
        let filtered = this.uploadedFileList.filter((value, index, arr) => {
            return value.uploadId !== id;
        });
        this.uploadedFileList = filtered;
        $(event.delegateTarget).remove();
    }

    uploadFiles(uploadFunction): Promise<void> {
        let me = this;
        return new Promise((accept, reject) => {
            let files = me.$el.find('input').prop('files');
            let fileProms = [];
            if(files.length > 0) {
                //We've some files, we upload them
                for(var i = 0; i < files.length; i++) {
                    let id = parseInt(Math.random() * 100000 + '');
                    fileProms.push(uploadFunction(id, files[i]));
                }
            }
            Promise.all(fileProms)
                .then((result) => {
                    let files = me.$el.find('input').prop('files');
                    for(let i= 0; i < files.length; i++) {
                        let file = files[i];
                        let uploadResult = result[i][0];
                        this.uploadedFileList.push({
                            name: file.name,
                            type: file.type,
                            uploadId: uploadResult.id
                        });
                    }
                    me.trigger('completed.fileupload');
                    this.setModelValue(this.uploadedFileList.map((value, index, arr) => {
                        return value.uploadId;
                    }));
                    accept();
                })
                .catch((err) => {
                    me.trigger('error.fileupload');
                    reject(err);
                });
        });
    }

    getPendingDocuments () {
        return this.uploadedFileList;
    }
}
