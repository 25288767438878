'use strict';

import TextField from './Text';

class DropDownField extends TextField {
    render () : any {
        super.render();
        this.$el.find('label').on('click', this.openDropdown.bind(this));
    }

    private openDropdown() {
        (this.$el.find('select')as any).select2('open');
    }
}

export default DropDownField;