import { MobileRequest, MessageResponse, Server } from '@sai/protocol';

class SubscribeRequest extends MobileRequest {
    private controller: string;
    private service: string;
    private pages: { [key: string] : any };
    private message: typeof MessageResponse;

    constructor(pages: {[key: string]: any}) {
        super('subscribe', 'subscribe', 'POST', null);
        this.pages = pages;
    }

    getUrl() {
        let url = this.controller;
        url += '/' + this.service;

        return url;
    }

    getSerializable(): { [prop: string]: any } {
        return {
            request1: this.pages
        }
    }

    setMessageContext(response: typeof MessageResponse) {
        this.message = response;
    }
}

export default SubscribeRequest;