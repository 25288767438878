import { PortalView } from '../PortalView';
import { Context } from '@sai/protocol';
import { Combo } from '@sai/configuration';
import I18NUtils from '../../utils/I18NUtils';
import * as _ from "lodash";

class FamilyMemberView extends PortalView {
    public static key: number;
    private id: number;
    private label: string;
    private role: string;
    private values: typeof Context;
    private combo: typeof Combo;
    private titre: string;
    private allowRemoval: boolean;
    private lang: string;
    private initialGroupsConfig: Array<any>;
    private _isValid: boolean;

    constructor(options: { label: string, role: string, combo: typeof Combo, allowRemoval: boolean, values: typeof Context, [key:string]: any, valid?:boolean}) {
        super(options);
        this.label = options.label;
        this.role = options.role;
        this.values = options.values;
        this.id = ++FamilyMemberView.key;
        this.combo = options.combo;
        this.titre = options.titre;
        this.template = require('../../templates/family/Member.ejs');
        this.allowRemoval = options.allowRemoval;
        this.lang = options.lang;
        this._isValid = options.valid !== undefined ? options.valid : true;
    }

    public render(): void {
        this.$el.addClass(['family-member', 'col-lg-3', 'col-md-4', 'col-sm-6']);
        this.$el.html(this.template({ member: this, add_label: I18NUtils.getText('subscribe_new_stakeholder', this.lang, {}), valid: this._isValid}));
    }

    public getId(): number {
        return this.id;
    }

    public getLabel(): string {
        return this.label;
    }

    public getRole(): string {
        return this.role;
    }

    public getRolesCombo(): typeof Combo {
        return this.combo;
    }

    public getValues(): typeof Context {
        return this.values;
    }

    public setValues(values: typeof Context): void {
        this.values = values;
        let fieldsValues = this.getFieldsValues(values.attributes.screen);
        this.label = fieldsValues['NOM'] + ' ' + fieldsValues['PRENOM'];
        this.role = fieldsValues['ROLE'];
        this.titre = fieldsValues['TITRE'];
    }

    public getTitre(): string {
        return this.titre;
    }

    public canBeRemoved(): boolean {
        return this.allowRemoval
    }

    private getFieldsValues(screen: Array<any>): Array<any> {
        const values = [];
        for(let n in screen) {
            let elem = screen[n];
            values[elem.key] = elem.value;
        }
        return values;
    }

    public getInitialGroupsConfig(): Array<any> {
        return this.initialGroupsConfig;
    }

    public setInitialGroupsConfig(groupConfig: Array<any>) {
        this.initialGroupsConfig = _.cloneDeep(groupConfig);
    }

    public setValid():void {
        this._isValid = true;
    }

    public isValid(): boolean {
        return this._isValid;
    }
}

FamilyMemberView.key = 0;

export default FamilyMemberView;