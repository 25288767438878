import { Context, Server, Task } from '@sai/protocol';
import SubscribeRequest from './requests/SubscribeRequest';
import ConfigUtils from './utils/ConfigUtils';
import I18NUtils, { I18NMessage } from './utils/I18NUtils';
import ServerUtils from './utils/ServerUtils';
import FamilyMemberView from './views/family/FamilyMemberView';
import FamilyView from './views/family/FamilyView';
import InitialRequestsView from './views/initialRequests/InitialRequestsView';
import RequestView from './views/initialRequests/RequestView';
import RecordView from './views/record/Record';
let moment = require('moment');
import StringUtils from './utils/StringUtils';
import ErrorUtils from './utils/ErrorUtils';

const { CClientConfiguration, AbstractTask, Combo, FieldInstance } = require('@sai/configuration');

let location = window.location;
let observer;

declare var grecaptcha: any;

let lang = $('html').attr('lang').toUpperCase() || 'FR';
declare var hiddenFields: Array<string>;

Server.initialize({
    //debug: true,
    serverUrl: location.origin + ConfigUtils.getRelativePath(),
    deviceType: 'web',
    callbacks: {
      //No automatic timeout
      sessionTimeoutCallback: undefined,
      //If there is an error, the whole server has a problem, we should abort for now
      globalFailureCallback: (error:any) => {
        console.log('Global failure while performing calls ' + error.title + ' ' + error.body);
      },
      //If the authentication is missing (server didn't make any call and his session got invalidated)
      //then it reconnects automatically to the SAINet server.
      missingAuthCallback: (message: any, resolve: any, reject: any) => {
          reject('There shouldn\'t be any require authentication');
      },
      ongoingMessageCallback: undefined,
      //Possible server display messages are discarded as there is no gui
      displayMessageCallback: undefined,
      i18NEntryCallback: (code: string) => I18NUtils.getMessage(code, lang, {})
    },
    timeout: 0
  });

let records = {};
let intervenantIds = [];
let currentRecord:RecordView;


window['recaptchaCallback'] = function() {
    $('#submitBtn').removeAttr('disabled');
};

window['recaptchaErrorCallback'] = function() {
    $('#submitBtn').prop('disabled', true);
}

let applyPostDisplayOperation = (curRec) => {
    if(curRec.panelId === 'DEMANDE_INITIALE') {
        let amount = familyManager ? familyManager.getRevenuAmount() : 0;
        if (curRec.getField('REVENU') && amount >= 0) {
            curRec.getField('REVENU').setModelValue(amount);
            curRec.onFieldChange(curRec.getField('REVENU').getState(), false, []);
        } else if(curRec.getField('HIDDEN_REVENU') && amount === -1) {
            curRec.getField('HIDDEN_REVENU').setModelValue('1');
            curRec.onFieldChange(curRec.getField('HIDDEN_REVENU').getState(), false, []);
        }
    }
}

function onNextClicked(currentIndex: number, maxIndex: number) {
    let validation = false;
    if(currentIndex === familyIndex) {
        if(familyManager && familyManager.isVisible()) {
            validation = familyManager.validate();
        } else {
            validation = currentRecord.validate();
        }
    } else if(currentIndex === initialRequestIndex) {
        if(requestManager && requestManager.isVisible()) {
            validation = requestManager.validate();
        } else {
            validation = currentRecord.validate();
        }
    } else {
        validation = maxIndex < 0 ? false : records[currentIndex].validate();
    }
    let allPromise = [];
    if((familyManager && familyManager.isVisible()) || (requestManager && requestManager.isVisible())) { 
       allPromise.push(Promise.resolve());
    } else {
        if(records[currentIndex]) {
            allPromise.push(validatePage(records[currentIndex].panelId, currentIndex));
        } else if(currentRecord) {
            let el = currentRecord.$el;
            let index = el.data('index') + '-' + el.data('occurrence');
            allPromise.push(validatePage(records[index].panelId, index));
        }
    }
    Promise.all(allPromise)
        .then(([validationResult]) => {
            if(currentIndex === maxIndex && validation
                && (currentIndex !== familyIndex || !familyManager || familyManager.isVisible())
                && (currentIndex !== initialRequestIndex || !requestManager || requestManager.isVisible())) {
                $('.missing-required').hide();
                $('.sainet-validation-errors').hide();
                $('#subscribe-panel').hide();
                $('#almost-done-panel #almost-done').show();
                $('#almost-done-panel .success').hide();
                $('#almost-done-panel').show();
                //Mandatory reset of the captcha everytime we go on the final page to avoid unlucky timeouts
                if(grecaptcha !== undefined) {
                    grecaptcha.reset();
                }
            } else {
                if (maxIndex < 0 || validation) {
                    $('.missing-required').hide();
                    $('.sainet-validation-errors').hide();
                    $('.pages-container').children('div:not(.family-view,.initial-request-list-view)').hide();
                    var operationPerformed:boolean = false;
                    if(familyIndex === currentIndex && !operationPerformed) {
                        //Called next on family view
                        if (familyManager && familyManager.isVisible()) {
                            if (familyManager.validate()) {
                                $('.pages-container').children('div .family-view').hide();
                            } else {
                                operationPerformed = true;
                            }
                        } else {
                            //Adding record to family if record validates()
                            let context: typeof Context = new Context({});
                            currentRecord.onContextRecordRequest(context, false);
                            if (currentlyModifiedIt !== undefined) {
                                familyManager.updateMember(currentlyModifiedIt, context, currentRecord);
                            } else if(familyManager) {
                                familyManager.createNewMember(context, currentRecord);
                            }
                            if(familyManager) {
                                currentlyModifiedIt = undefined;
                                $('.pages-container').children('div[data-index=' + currentIndex + ']').find('.family-back-btn').hide();
                                $('.pages-container').children('div[data-index=' + currentIndex + ']').find('.prev-btn').show();
                                $('.pages-container').children('div[data-index=' + (currentIndex) + '][data-type!=record]').show();
                                $('.recordView[data-index="'+currentIndex+'"]:visible').hide();
                                operationPerformed = true;
                            }
                        }
                    }
                    if(initialRequestIndex === currentIndex && !operationPerformed) {
                        //Called next on initial request list view
                        if (requestManager && requestManager.isVisible()) {
                            if (requestManager.validate()) {
                                $('.pages-container').children('div .initial-request-list-view').hide();
                            } else {
                                operationPerformed = true;
                            }
                        } else {
                            //Adding record if record validates()
                            let validationTitle = validationResult.sessionVars.PAGE_TITLE;
                            let context: typeof Context = new Context({});
                            currentRecord.onContextRecordRequest(context, false);
                            if (currentlyModifiedIt !== undefined) {
                                requestManager.updateRequest(currentlyModifiedIt, context, currentRecord, StringUtils.resolveTextProperties(validationTitle, getFieldStrValues(context)));
                            } else if(requestManager) {
                                requestManager.createNewRequest(context, currentRecord, StringUtils.resolveTextProperties(validationTitle, getFieldStrValues(context)));
                            }
                            if(requestManager) {
                                currentlyModifiedIt = undefined;
                                $('.pages-container').children('div[data-index=' + currentIndex + ']').find('.request-back-btn').hide();
                                $('.pages-container').children('div[data-index=' + currentIndex + ']').find('.prev-btn').show();
                                $('.pages-container').children('div[data-index=' + (currentIndex) + '][data-type!=record]').show();
                                operationPerformed = true;
                            }
                        }
                    }
                    if(familyIndex === currentIndex + 1 && !operationPerformed) {
                        $('.pages-container').children('div[data-index=' + (currentIndex + 1) + '][data-type!=record]').show();
                        $('li[data-index=' + currentIndex + ']').removeClass('active');
                        $('li[data-index=' + (currentIndex + 1) + ']').addClass('active');
                        operationPerformed = true;
                    }
                    if(initialRequestIndex === currentIndex + 1 && requestManager && !operationPerformed) {
                        $('.pages-container').children('div[data-index=' + (currentIndex + 1) + '][data-type!=record]').show();
                        $('li[data-index=' + currentIndex + ']').removeClass('active');
                        $('li[data-index=' + (currentIndex + 1) + ']').addClass('active');
                        operationPerformed = true;
                    }
                    if(!operationPerformed){
                        $('.pages-container').children('div[data-index=' + (currentIndex + 1) + ']').show();
                        $('li[data-index=' + currentIndex + ']').removeClass('active');
                        $('li[data-index=' + (currentIndex + 1) + ']').addClass('active');
                        currentRecord = records[currentIndex + 1];
                        applyPostDisplayOperation(currentRecord);
                        operationPerformed = true;
                    }
                    window.scrollTo(0,0);
                }
            }
        })
        .catch((error) => {
            // The page is not valid
            $('.sainet-validation-errors').html(ErrorUtils.getErrorDescription(error, lang));
            $('.sainet-validation-errors').show();
        });
}

let onPrevClicked = (currentIndex: number): void => {
    $('.pages-container').children('div').hide(200);
    $('li[data-index=' + currentIndex + ']').removeClass('active');
    $('li[data-index=' + (currentIndex - 1) + ']').addClass('active');
    if ((familyIndex === currentIndex - 1 && familyManager) || (initialRequestIndex === currentIndex - 1 && requestManager)) {
        $('.pages-container').children('div[data-index=' + (currentIndex - 1) + '][data-type!=record]').show(200);
    } else {
        $('.pages-container').children('div[data-index=' + (currentIndex - 1) + ']').show(200);
    }
    window.scrollTo(0, 0);
}

let onFamilyBackClicked = (index: number): void => {
    if(currentlyModifiedIt === undefined) {
        // Called back on a new record
        currentRecord.$el.remove();
    } else {
        currentRecord.setValuesAndPositions(familyManager.getMember(currentlyModifiedIt).getInitialGroupsConfig(), true);
    }
    currentlyModifiedIt = undefined;
    $('.recordView#INTERVENANT_RESPONSABLE').hide();
    familyManager.$el.show();
    $('.pages-container').children('div[data-index=' + index + ']').find('.family-back-btn').hide(200);
    $('.pages-container').children('div[data-index=' + index + ']').find('.prev-btn').show(200);
}

let onRequestBackClicked = (index: number): void => {
    if(currentlyModifiedIt === undefined) {
        // Called back on a new record
        currentRecord.$el.remove();
    } else {
        currentRecord.setValuesAndPositions(requestManager.getRequest(currentlyModifiedIt).getInitialGroupsConfig(), true);
    }
    currentlyModifiedIt = undefined;
    $('.recordView#DEMANDE_INITIALE').hide();
    requestManager.$el.show();
    $('.pages-container').children('div[data-index=' + index + ']').find('.request-back-btn').hide(200);
    $('.pages-container').children('div[data-index=' + index + ']').find('.prev-btn').show(200);
}

let onFieldChangeErrorCallback = (result): void => {
    let errorDisplay = $('.sainet-validation-errors');
    errorDisplay.html(ErrorUtils.getErrorDescription(result, lang));
    errorDisplay.show();
    errorDisplay[0].scrollIntoView(false);
}

let onFieldChangeCallback = (result): void => {
    $('.sainet-validation-errors').hide();
}

let familyManager: FamilyView = null;
let familyIndex = -999;
let requestManager: InitialRequestsView;
let initialRequestIndex = -999;

let newIntervenant = function(groups, task) {
    let recordHolder = $('<div></div>');
    let existingMembers = $('.recordView#INTERVENANT_RESPONSABLE');
    if(existingMembers.length === 0) {
        $('.family-view').after(recordHolder);
    } else {
        $(existingMembers[existingMembers.length-1]).after(recordHolder);
    }
    let index = familyManager.getMembers().length+1;
    let dataIndex = $('.family-view').data('index');
    recordHolder.attr('id', 'INTERVENANT_RESPONSABLE');
    recordHolder.attr('data-index', dataIndex);
    recordHolder.attr('data-occurrence', index);
    recordHolder.attr('data-type', 'record');
    let record = new RecordView({
        displayMode: 'tablet',
        el: recordHolder,
        groups: groups,
        dominantColor: '#7EB953',
        contextType: 'page',
        domainContext: domainContext,
        taskConfig: task,
        panelId: 'INTERVENANT_RESPONSABLE',
        hiddenFields: hiddenFields,
        fieldChangeCallback: onFieldChangeCallback,
        fieldChangeErrorCallback: onFieldChangeErrorCallback,
        fileUploadCallback: uploadFiles
    });
    record.setKeys({
        dossierId: '',
        pageOccurrence: 1,
        pageId: 'INTERVENANT_RESPONSABLE'
    });
    let curMembers = familyManager.getMembers();
    if(curMembers.length > 0) {
        let lastMember = curMembers[curMembers.length - 1];
        let values = lastMember.getValues().attributes.screen;
        let fieldsToCopy = ['ADRESSE1','ADRESSE2','NPALOCALITE','NPALOCALITE_NA','PAYS'];
        for (let key in values) {
            if (fieldsToCopy.indexOf(values[key].key) >= 0) {
                let fieldView = record.getField(values[key].key);
                let fieldConfig = fieldView.getConfig().getClonedConfig();
                fieldConfig.value = values[key].value;
                record.setField(new FieldInstance(fieldConfig, null, null, null, this.domainContext), false, false);
            }
        }
    }
    records[dataIndex + '-' + index] = record;
    record.render();
    familyManager.hide();
    $('.pages-container').children('div[data-index=' + familyIndex + ']').find('.prev-btn').hide(200);
    $('.pages-container').children('div[data-index=' + familyIndex + ']').find('.family-back-btn').show(200);
    currentRecord = record;
}

let currentlyModifiedIt = undefined;

let modifyIntervenant = function (member: FamilyMemberView, recordView:RecordView) {
    member.setInitialGroupsConfig(recordView.getGroups());
    currentlyModifiedIt = member.getId();
    familyManager.hide();
    $('.pages-container').children('div[data-index=' + familyIndex + ']').find('.prev-btn').hide(200);
    $('.pages-container').children('div[data-index=' + familyIndex + ']').find('.family-back-btn').show(200);
    recordView.$el.show();
    currentRecord = recordView;
}

let removeMember = function (index: number, recordView:RecordView) {
    recordView.$el.remove();
}

let newInitalRequest = function(groups, task) {
    let recordHolder = $('<div></div>');
    let existingRequests = $('.recordView#DEMANDE_INITIALE');
    if(existingRequests.length === 0) {
        $('.initial-request-list-view').after(recordHolder);
    } else {
        $(existingRequests[existingRequests.length-1]).after(recordHolder);
    }
    let index = requestManager.getRequests().length+1;
    let dataIndex = $('.initial-request-list-view').data('index');
    recordHolder.attr('id', 'DEMANDE_INITIALE');
    recordHolder.attr('data-index', dataIndex);
    recordHolder.attr('data-occurrence', index);
    recordHolder.attr('data-type', 'record');
    let record = new RecordView({
        displayMode: 'tablet',
        el: recordHolder,
        groups: groups,
        dominantColor: '#7EB953',
        contextType: 'page',
        domainContext: domainContext,
        taskConfig: task,
        panelId: 'DEMANDE_INITIALE',
        hiddenFields: hiddenFields,
        fieldChangeCallback: onFieldChangeCallback,
        fieldChangeErrorCallback: onFieldChangeErrorCallback,
        fileUploadCallback: uploadFiles
    });
    record.setKeys({
        dossierId: '',
        pageOccurrence: 1,
        pageId: 'DEMANDE_INITIALE'
    });
    records[dataIndex + '-' + index] = record;
    record.render();
    applyPostDisplayOperation(record);
    requestManager.hide();
    $('.pages-container').children('div[data-index=' + initialRequestIndex + ']').find('.prev-btn').hide(200);
    $('.pages-container').children('div[data-index=' + initialRequestIndex + ']').find('.request-back-btn').show(200);
    currentRecord = record;
}

let modifyRequest = function (request: RequestView, recordView:RecordView) {
    request.setInitialGroupsConfig(recordView.getGroups());
    currentlyModifiedIt = request.getId();
    requestManager.hide();
    applyPostDisplayOperation(recordView);
    $('.pages-container').children('div[data-index=' + initialRequestIndex + ']').find('.prev-btn').hide(200);
    $('.pages-container').children('div[data-index=' + initialRequestIndex + ']').find('.request-back-btn').show(200);
    recordView.$el.show();
    currentRecord = recordView;
}

let removeRequest = function (index: number, recordView:RecordView) {
    recordView.$el.remove();
}

$('#submitBtn').on('click', () => {
    let pan = $('#almost-done-panel');

    pan.find('#almost-done').hide();
    pan.find('.success').hide();
    pan.find('.failure').hide();
    pan.find('.backdrop').show();

    let finalSubscription = {
        captcharesp: grecaptcha.getResponse(),
        pages: {},
        selectedDomains: []
    };

    let dynamicPageList = [];
    $('.progress-indicator').children('li').each((index, elem) => {
        dynamicPageList.push(elem.getAttribute('data-pageid'));
    })

    Object.keys(records).forEach((key) => {
        let pageToAdd = records[key];
        let pageId = pageToAdd.getKeys().pageId;
        if(pageId === 'INTERVENANT_RESPONSABLE') {
            finalSubscription.pages[pageId] = [];
            let members = familyManager.getMembers();
            for (let i in members) {
                let mem = members[i];
                finalSubscription.pages[pageId].push(mem.getValues().toJSON());
            }
        } else if(pageId === 'DEMANDE_INITIALE') {
            finalSubscription.pages[pageId] = [];
            if(requestManager) {
                let requests = requestManager.getRequests();
                for (let i in requests) {
                    let request = requests[i];
                    finalSubscription.pages[pageId].push(request.getValues().toJSON());
                }
            } else {
                let context: typeof Context = new Context({});
                pageToAdd.onContextRecordRequest(context, false);
                finalSubscription.pages[pageId].push(context.toJSON());
            }
        } else {
            let context: typeof Context = new Context({});
            pageToAdd.onContextRecordRequest(context, false);
            finalSubscription.pages[pageId] = context.toJSON();
        }
    });
    $('#welcome-panel').find('input').each(function() {
        if($(this).prop('checked')) {
            finalSubscription.selectedDomains.push($(this).val());
        }
    });
    let req = new SubscribeRequest(finalSubscription);
    Server.performRequest(req)
        .then((response) => {
            intervenantIds = JSON.parse(response.intervenants);
            pan.find('#almost-done').hide();
            if(requestManager && requestManager.getRequests().length > 1) {
                let requests = requestManager.getRequests();
                let output = '<div class="submit-output-message">' + response.message + '</div>';
                output += '<div class="detailed-output">';
                output    += '<table class="pages-results">';
                const pagesResults = JSON.parse(response.pagesResults);
                let it = 0;
                pagesResults.forEach(result => {
                    output += '<tr>';
                    output += '<td>';
                    output += requests[it].getLabel();
                    output += '</td>';
                    output += '<td>';
                    if(result.success) {
                        output += I18NUtils.getText('request_successful', lang, {});
                    } else {
                        output += I18NUtils.getText('request_error', lang, {});
                    }
                    output += '</td>';
                    output += '</tr>';
                    it++;
                });
                output += '</table>';
                output += '</div>';
                pan.find('.success').find('.subtitle').append($(output));
            } else {
                pan.find('.success').find('.subtitle').html(response.message);
            }
            pan.find('.success').show();
        })
        .catch((error) => {
            pan.children('.subtitle').hide();
            pan.find('#almost-done').show();
            pan.find('.success').hide();
            pan.find('.failure').show();
            pan.find('.failure').find('.subtitle').html(ErrorUtils.getErrorDescription(error, lang));
            
        })
        .finally(() => {
            pan.find('.backdrop').hide();
        })
});

CClientConfiguration.initialize(Server, lang, {});
let domainContext = ServerUtils.getDomainContext('subscribe/ANY');
Promise.all([CClientConfiguration.getTask('CRM06', domainContext, lang), CClientConfiguration.getCombo('CRMPRJ.INTERVENANT_RESPONSABLE.ROLE', domainContext, undefined, lang)])
    .then(([task, combo]: [typeof AbstractTask, typeof Combo]) => {
        $(document).ready(() => {
            let dynamicPageList = [];
            $('.progress-indicator').children('li').each((index, elem) => {
                dynamicPageList.push(elem.getAttribute('data-pageid'));
            })

            $('.loading-screen').hide();
            let counter = 0;
            for (let index in dynamicPageList) {
                // Create a new record with the page
                let pageId = dynamicPageList[index];
                if(pageId === 'INTERVENANT_RESPONSABLE') {
                    //We might have to inject a family panel
                    let occurrences = parseInt($('li[data-pageid=INTERVENANT_RESPONSABLE]').attr('data-count'));
                    if(occurrences === -1 || occurrences > 1) {
                        let familyHolder = $('<div></div>').appendTo($('.pages-container'));
                        familyManager = new FamilyView({
                            el: familyHolder,
                            maxCount: occurrences,
                            rolesCombo: combo,
                            lang: lang
                        });
                        familyHolder.attr('data-index', counter);
                        familyHolder.attr('data-type', 'family');
                        familyHolder.hide();
                        familyManager.render();
                    }
                    familyIndex = counter;
                } else if(pageId === 'DEMANDE_INITIALE') {
                    //We might have to inject a initialRequests panel
                    let reqOccurrences = parseInt($('li[data-pageid=DEMANDE_INITIALE]').attr('data-count'));
                    if(reqOccurrences === -1 || reqOccurrences > 1) {
                        let initialRequestHolder = $('<div></div>').appendTo($('.pages-container'));
                        requestManager = new InitialRequestsView({
                            el: initialRequestHolder,
                            maxCount: reqOccurrences,
                            lang: lang
                        });
                        initialRequestHolder.attr('data-index', counter);
                        initialRequestHolder.attr('data-type', 'initialRequest');
                        initialRequestHolder.hide();
                        requestManager.render();
                    }
                    initialRequestIndex = counter;
                }
                let pageConfig = task.pages[pageId];
                if (pageConfig === undefined) {
                    console.error('Page configuration not found ', pageId);
                } else {
                    if((pageId === 'INTERVENANT_RESPONSABLE' && familyManager) || (pageId === 'DEMANDE_INITIALE' && requestManager)) {
                        if (pageId === 'INTERVENANT_RESPONSABLE') {
                            familyManager.listenTo(familyManager, 'family.newmember', newIntervenant.bind(familyManager,pageConfig.getGroups(), task));
                            familyManager.listenTo(familyManager, 'family.modifymember', modifyIntervenant.bind(familyManager));
                            familyManager.listenTo(familyManager, 'family.removedmember', removeMember.bind(familyManager));
                        } else if(pageId === 'DEMANDE_INITIALE' && requestManager) {
                            requestManager.listenTo(requestManager, 'requests.newrequest', newInitalRequest.bind(requestManager, pageConfig.getGroups(), task));
                            requestManager.listenTo(requestManager, 'requests.modifyrequest', modifyRequest.bind(requestManager));
                            requestManager.listenTo(requestManager, 'requests.removedrequest', removeRequest.bind(requestManager));
                        }
                    } else {
                        let recordHolder = $('<div></div>').appendTo($('.pages-container')).attr('id', pageId);
                        let record = new RecordView({
                            displayMode: 'tablet',
                            el: recordHolder,
                            groups: pageConfig.getGroups(),
                            dominantColor: '#7EB953',
                            contextType: 'page',
                            domainContext: domainContext,
                            taskConfig: task,
                            panelId: pageId,
                            hiddenFields: hiddenFields,
                            fieldChangeCallback: onFieldChangeCallback,
                            fieldChangeErrorCallback: onFieldChangeErrorCallback,
                            fileUploadCallback: uploadFiles
                        });
                        record.setKeys({
                            dossierId: '',
                            pageOccurrence: 1,
                            pageId: pageId
                        })
                        records[counter] = record;
                        recordHolder.attr('data-index', counter);
                        recordHolder.attr('data-type', 'record');
                        recordHolder.hide();
                        record.render();
                    }

                    //Displaying a button that will allow to display the next page
                    let buttonsHolder = $('<div></div>').appendTo($('.pages-container'));
                    buttonsHolder.attr('data-index', counter);
                    buttonsHolder.attr('data-type', 'button');
                    buttonsHolder.addClass('next-page-holder');

                    if (counter > 0) {
                        let prevButton = $('<button></button>');
                        prevButton.addClass(['btn', 'btn-primary', 'prev-btn', 'bebsi-button']);
                        prevButton.on('click', onPrevClicked.bind(this, counter));
                        prevButton.text(I18NUtils.getText('subscribe_previous_panel',lang, {}));
                        buttonsHolder.append(prevButton);
                    }
                    if (pageId === 'INTERVENANT_RESPONSABLE') {
                        let famBackButton = $('<button></button>');
                        famBackButton.addClass(['btn', 'btn-primary', 'family-back-btn', 'bebsi-button']);
                        famBackButton.on('click', onFamilyBackClicked.bind(this, counter));
                        famBackButton.text(I18NUtils.getText('subscribe_back_to_family',lang, {}));
                        buttonsHolder.append(famBackButton);
                    } else if(pageId === 'DEMANDE_INITIALE' && requestManager) {
                        let backbutton = $('<button></button>');
                        backbutton.addClass(['btn', 'btn-primary', 'request-back-btn', 'bebsi-button']);
                        backbutton.on('click', onRequestBackClicked.bind(this, counter));
                        backbutton.text(I18NUtils.getText('subscribe_back_to_requests',lang, {}));
                        buttonsHolder.append(backbutton);
                    }
                    {
                        let nextButton = $('<button></button>');
                        nextButton.addClass(['btn', 'btn-primary', 'next-btn', 'bebsi-button']);
                        nextButton.on('click', onNextClicked.bind(this, counter, dynamicPageList.length - 1));
                        nextButton.text(I18NUtils.getText('subscribe_next_panel',lang, {}));
                        buttonsHolder.append(nextButton);
                    }
                    buttonsHolder.hide();
                    counter++;
                }
            }
            $('#noSubmitBtn').on('click', () => {
                $('#almost-done-panel').hide(200);
                $('#subscribe-panel').show(200);
            });
            onNextClicked(-1, -2);

            $('.progress-indicator').find('li').each((index, element) => {
                $(element).attr('data-index', index);
            });

            $('#startSubscription').on('click', () => {
                let hasSelected = false;
                $('#welcome-panel').find('input').each(function() {
                    hasSelected = hasSelected || $(this).prop('checked');
                });
                if($('#welcome-panel').find('input').length > 0 && !hasSelected) {
                    $('#welcome-panel').find('.error-message').show();
                } else {
                    $('#welcome-panel').find('.error-message').hide();
                    $('#welcome-panel').hide(200, () => {
                        $('#subscribe-panel').show(200);
                    });
                }
            });

            $('[data-id="DATENAISSANCE"]').find('.date').on('change.datetimepicker', getAttendanceRange.bind(this));
            

            $('#homeBtn').on('click', () => {
                let target = $('#homeBtn').attr("target");
                window.location.href = location.origin + "/" + target;
            });

            Object.keys(records).forEach(key => {
                let record = records[key];
                record.listenTo(record, 'upload-button-click.fileupload', uploadFiles.bind(record));
            });
        });
    }).catch((err: I18NMessage) => {
        console.error(err);
    });

let getAttendanceRange = function(evt:any) {
    if(evt.date) { // Prevent the event to be trigger twice
        const remoteRequest = new Task.GetNotificationRequest(domainContext, "CRM06", "PORTAL_NOTIFICATION");
        remoteRequest.setNotificationArgument("function", "getAttendanceMinDate");
        let birthdate:typeof moment = ($('[data-id="DATENAISSANCE"]').find('.date') as any).datetimepicker('date');
        if(birthdate && birthdate.isValid()) {
            let m = moment(birthdate, 'DD/MM/YYYY');
            remoteRequest.setNotificationArgument("birthdate", m.format('YYYY-MM-DD'));
            $('.sainet-validation-errors').hide();
            Server.performRequest(remoteRequest)
            .then((response) => {
                // Update the minDate of the FM_PAGE_START_DATE of DEMANDE_INITIALE
                let minDate = undefined;
                let maxDate = undefined;
                if(response.sessionVars) {
                    minDate = response.sessionVars["ATTENDANCE_MIN_DATE"];
                    maxDate = response.sessionVars["ATTENDANCE_MAX_DATE"];
                }
                setPickerDates(minDate, maxDate);
            })
            .catch((err) => {
                $('.sainet-validation-errors').html(ErrorUtils.getErrorDescription(err, lang));
                $('.sainet-validation-errors').show();
            });
        }
    }
}

let validatePage = function(pageId: string, pageIdx: any): Promise<any> {
    return new Promise((accept, reject) => {
        const remoteRequest = new Task.GetNotificationRequest(domainContext, "CRM06", "PORTAL_NOTIFICATION");
        remoteRequest.setNotificationArgument("function", "validatePage");
        remoteRequest.setNotificationArgument("PAGE_ID", pageId);
        remoteRequest.setNotificationArgument("ISO_LANGUAGE", lang);
        let pageToAdd = records[pageIdx];
        let context: typeof Context = new Context({});
        pageToAdd.onContextRecordRequest(context, false);
        context.attributes.screen.forEach(element => {
            remoteRequest.setNotificationArgument(element.key, element.value);
        });
        Server.performRequest(remoteRequest)
            .then((response) => {
                accept(response);
            })
            .catch((error) => {
                reject(error);
            });
    });    
}

let restoreMinMaxDate = function(mutationsList) {
    let elem = $('#DEMANDE_INITIALE').find('[data-id="FM_PAGE_START_DATE"] .date');
    if(elem) {
        let startDate = sessionStorage['request-min-date'];
        let endDate = sessionStorage['request-max-date'];
        ($(elem) as any).datetimepicker('minDate', startDate !== undefined ? moment(startDate, "YYYY-MM-DD hh:mm:ss") : false);
        ($(elem) as any).datetimepicker('maxDate', endDate !== undefined ? moment(endDate, "YYYY-MM-DD hh:mm:ss") : false);
    }
}

let setPickerDates = function(startDate, endDate) {
    let elem = $('#DEMANDE_INITIALE').find('[data-id="FM_PAGE_START_DATE"] .date');
    if(elem && elem.length > 0) {
        ($(elem) as any).datetimepicker('minDate', startDate !== undefined ? moment(startDate, "YYYY-MM-DD hh:mm:ss") : false);
        ($(elem) as any).datetimepicker('maxDate', endDate !== undefined ? moment(endDate, "YYYY-MM-DD hh:mm:ss") : false);
        if(startDate) {
            sessionStorage['request-min-date'] = startDate
        } else {
            sessionStorage.removeItem('request-min-date');
        }
        if(endDate) {
            sessionStorage['request-max-date'] = endDate
        } else {
            sessionStorage.removeItem('request-max-date');
        }
        if(!observer) {
            observer = new MutationObserver(restoreMinMaxDate);
            observer.observe($('#DEMANDE_INITIALE')[0], { attributes: true, childList: true });
        }
    }
}

$('#newBtn').on('click', () => {
    CClientConfiguration.getTask('CRM06', domainContext)
    .then((task: typeof AbstractTask) => {
        let dynamicPageList = [];
        $('.progress-indicator').children('li').each((index, elem) => {
            dynamicPageList.push(elem.getAttribute('data-pageid'));
        })

        let firstNonIntervenantPageIndex = undefined;
        let intervenantPageIndex = 0;
        let familyMembers = familyManager.getMembers();
        for (let index in dynamicPageList) {
            // Create a new record with the page
            let pageId = dynamicPageList[index];
            if(pageId === 'INTERVENANT_RESPONSABLE') {
                for(let memberIndex in familyMembers) {
                    let member:FamilyMemberView = familyMembers[memberIndex];
                    member.getValues().updateField('CRMADRLINK','key',intervenantIds[intervenantPageIndex].adrlink);
                    intervenantPageIndex++;
                }
            } else if(pageId === 'DEMANDE_INITIALE') {
                if(requestManager) {
                    requestManager.deleteAllRequests();

                    Object.keys(records).forEach((key) => {
                        if(key === index || key.startsWith(index+'-')) {
                            records[key] = undefined;
                        }
                    })
                } else {
                    let pageConfig = task.pages[pageId];
                    if (pageConfig === undefined) {
                        console.error('Page configuration not found ', pageId);
                    } else {
                        let recordHolder = $($('.pages-container .recordView')[index]);
                        recordHolder.empty();
                        let record = new RecordView({
                            displayMode: 'tablet',
                            el: recordHolder,
                            groups: pageConfig.getGroups(),
                            dominantColor: '#7EB953',
                            contextType: 'page',
                            domainContext: domainContext,
                            taskConfig: task,
                            panelId: pageId,
                            hiddenFields: hiddenFields,
                            fieldChangeCallback: onFieldChangeCallback,
                            fieldChangeErrorCallback: onFieldChangeErrorCallback,
                            fileUploadCallback: uploadFiles
                        });
                        record.setKeys({
                            dossierId: '',
                            pageOccurrence: 1,
                            pageId: pageId
                        })
                        records[index]=record;
                        recordHolder.attr('data-index', index);
                        recordHolder.attr('data-type', 'record');
                        recordHolder.hide();
                        record.render();
                    }
                }
                if(firstNonIntervenantPageIndex === undefined) {
                    firstNonIntervenantPageIndex = index;
                } else {
                    $('.pages-container div[data-index="'+index+'"]:visible').hide();
                }
            } else {
                let pageConfig = task.pages[pageId];
                if (pageConfig === undefined) {
                    console.error('Page configuration not found ', pageId);
                } else {
                    let recordHolder = $($('.pages-container .recordView')[index]);
                    recordHolder.empty();
                    let record = new RecordView({
                        displayMode: 'tablet',
                        el: recordHolder,
                        groups: pageConfig.getGroups(),
                        dominantColor: '#7EB953',
                        contextType: 'page',
                        domainContext: domainContext,
                        taskConfig: task,
                        panelId: pageId,
                        hiddenFields: hiddenFields,
                        fieldChangeCallback: onFieldChangeCallback,
                        fieldChangeErrorCallback: onFieldChangeErrorCallback,
                        fileUploadCallback: uploadFiles
                    });
                    record.setKeys({
                        dossierId: '',
                        pageOccurrence: 1,
                        pageId: pageId
                    })
                    records[index]=record;
                    recordHolder.attr('data-index', index);
                    recordHolder.attr('data-type', 'record');
                    recordHolder.hide();
                    record.render();
                    if(firstNonIntervenantPageIndex === undefined) {
                        firstNonIntervenantPageIndex = index;
                    } else {
                        $('.pages-container div[data-index="'+index+'"]:visible').hide();
                    }
                }
            }
        }

        $('li[data-index]').removeClass('active');
        $('li[data-index=' + firstNonIntervenantPageIndex + ']').addClass('active');
        $('.next-page-holder').hide();
        $('.next-page-holder[data-index='+firstNonIntervenantPageIndex+']');
        $('#almost-done-panel').hide(200);
        $('#subscribe-panel').show(200);
        $('.pages-container>div[data-index!="'+firstNonIntervenantPageIndex+'"]:visible').hide();
        $('.pages-container').children('div[data-index=' + firstNonIntervenantPageIndex + ']').show(200);

        window.scrollTo(0,0);
    })
    .catch((error) => {
        let pan = $('#almost-done-panel');
        pan.children('.subtitle').hide();
        pan.find('#almost-done').show();
        pan.find('.success').hide();
        pan.find('.failure').show();
        pan.find('.failure').find('.subtitle').html(ErrorUtils.getErrorDescription(error, lang));
        pan.find('.loading-screen').hide();
    });
});

const getFieldStrValues = function(values: typeof Context): {[key:string]: string} {
    var fields = values.attributes.screen;
    let strVals = {};
    for (var key in fields) {
        strVals[fields[key].key] = fields[key].humanValue ? fields[key].humanValue : fields[key].value;
    }
    return strVals;
}

let uploadFiles = function(id, file) {
    return new Promise((accept, reject) => {
        ServerUtils.sendFile(id, file, '/subscribe/file', (prog) => {})
        .then(accept)
        .catch(reject);
    })
}