module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" class="upload-button-label bebsi-button">' +
((__t = ( uploadLabel )) == null ? '' : __t) +
'</label>\n<input id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" type="file" class="form-control changeableField" \ndata-id="' +
__e( fieldState.getId() ) +
' " value="' +
__e( fieldState.getValue() ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' \n' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' multiple accept="image/png, image/jpeg, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" >\n';
 if(uploadedFiles.length > 0) { ;
__p += '\n    <div class="uploaded-files">\n        ';
 for(var i=0; i < uploadedFiles.length; i++) { ;
__p += '\n        ';
 var file = uploadedFiles[i]; ;
__p += '\n          <span class="uploaded-file-item" id="' +
((__t = ( file.uploadId )) == null ? '' : __t) +
'"><i class="fas fa-times-circle"></i>' +
((__t = ( file.name )) == null ? '' : __t) +
'</span>\n        ';
 } ;
__p += '\n      </div>\n';
 } ;
__p += '\n<span class="field-loading" style="display: none;"><div class="spinner-holder"><i class="fas fa-sync-alt"></i></div></span>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>\n<span class="upload-error" style="display: none;"></span>';

}
return __p
}