module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<div>\n    <span>\n        <input id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" type="text" class="form-control changeableField" data-id="' +
__e( fieldState.getId() ) +
' " value="' +
__e( fieldState.getValue() ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' ';
 if(maxLength != undefined && parseInt(maxLength) > 0) { ;
__p += 'maxlength = "' +
__e( maxLength ) +
'"';
 } ;
__p += ' >\n        <span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>\n    </span>\n</div>';

}
return __p
}