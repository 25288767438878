module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="checkbox">\n  <input class="changeableField" type="checkbox" ' +
__e( fieldState.getValue() === '1' ? 'checked':'') +
' ' +
__e( fieldState.isReadOnly() || !fieldState.isEnabled() ? 'disabled' : '') +
' >\n  <span class="cr"><i class="cr-icon glyphicon glyphicon-ok"></i></span>\n  <label>\n      ' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'\n  </label>\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}