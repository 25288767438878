module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<button type="button" class="fieldButton btn btn-primary btn-block bebsi-button" aria-label="Left Align" data-id="' +
__e( fieldState.getId() ) +
' " ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' >' +
__e( fieldState.getLabel() ) +
'</button>\n';

}
return __p
}