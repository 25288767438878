module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<select id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" class="form-control changeableField selectpicker" data-id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" data-style="btn-inverse" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
'>\n    <option value=""></option>\n    <option value="' +
__e( fieldState.getValue() ) +
'" selected>' +
__e( fieldState.getValue() ) +
'</option>\n</select>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>\n';

}
return __p
}