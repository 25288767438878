module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h2 class="card-title font-weight-bold mb-1" style="color:' +
((__t = (dominantColor)) == null ? '' : __t) +
';">' +
((__t = ( title )) == null ? '' : __t) +
'</h2>\n<span class="help-icon group" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>\n<div class="fieldsContainer col-md-12 row"></div>\n';

}
return __p
}