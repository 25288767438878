'use strict';

import I18NUtils from '../../../utils/I18NUtils';
import DatePickerUtils from '../../../utils/DatePickerUtils';

let $ = require('jquery');
import FieldView from '../Field';

let moment = require('moment');

class DateField extends FieldView {
    protected visualDateFormat: string;
    protected disableOnClick: boolean;
    protected dominantColor: any;

    constructor(options: any) {
        super(options);

        this.visualDateFormat = 'dd/mm/yyyy';
        /*if(DeviceUtils.isMobile()) {
            this.fieldState.setReadOnly('true');
        }*/
        this.disableOnClick = options.readOnly && options.readOnly !== 'false';
        this.dominantColor = options.dominantColor;
    }

    protected evalDefaultValue(defaultValue: string) {
        /*return ScriptUtils.handleDateTransformation(defaultValue, this.getDefaultFormat());*/
    }

    protected getDefaultFormat() {
        return 'YYYY-MM-DD 00:00:00';
    }

    render() {
        super.render();
        this.onDOMUpdated();
    }

    setModelValue(value: string) {
        // Warning, value is a string. According to momentjs docs, we must
        // not parse a string directly as the result is inconsistent between
        // browsers, locale and timezones.
        // We want to parse the exact format to avoid such problems.
        let fieldValue, pickerDate;
        if (value !== undefined && value !== '' && value.indexOf('$') === -1) {
            let m = moment(value, 'YYYY-MM-DD HH:mm:ss');
            if(m.isValid()) {
                fieldValue = m.format('YYYY-MM-DD HH:mm:ss');
                pickerDate = m;
            } else {
                fieldValue = undefined;
                (this.$el.find('.date') as any).datetimepicker('clear');
            }
        } else {
            fieldValue = value;
        }

        this.fieldState.setValue(fieldValue);
    }

    onDOMUpdated () {
        super.onDOMUpdated();

        if (this.rendered) {
            let lang = $('html').attr('lang').toUpperCase() || 'FR';
            let me = this;
            let baseValue = this.getValue();
            let pickerParams: {[key: string]: any} = {
                format: this.visualDateFormat,
                i18n:  I18NUtils.getDatePickerIntl(lang)
            }
            if(baseValue !== '') {
                pickerParams.defaultDate = baseValue
                this.$el.find('label').addClass('active');
            };
            const opt: any = {
                localization: {
                    locale: 'fr'
                },
                display: {
                    components: {
                        clock: false
                    }
                }
            }
            let dtpElem = this.$el.find('.date');
            DatePickerUtils.buildDatePicker(dtpElem);
            me.setDominantColor(me.dominantColor);
            dtpElem.on('hide.datetimepicker', this.performChange.bind(this));
        }
    }

    onInputClicked (addon: any, event: any) {
        addon.click();
    }

    // Change the position to fixed in order to have the calendar on top of the modal
    fixCalendarPosition(field: any, calendar: any) {
        // If the position is already fixed, don't do it again, this would screw everything !
        if(calendar.css('position') !== 'fixed') {
            let isOnTop = calendar.css('top').indexOf('-') >= 0;
            let topPosition = 0;
            if(isOnTop) {
                topPosition = field.offset().top - calendar.height() - 10;
            } else {
                topPosition = field.offset().top + field.height();
            }
            calendar.css('top',topPosition + 'px');
            calendar.css('bottom', 'auto');
            calendar.css('left', 'auto');
            calendar.css('right', 'auto');
            calendar.css('position', 'fixed');
        }
    }

    getPickerOptions () {
        let options = {
            locale: 'fr',//User.getLocale().toLowerCase(),
            format: this.visualDateFormat
        };

        options['ignoreReadonly'] = true;

        return options;
    }

    getFieldElement () {
        return this.$el.find('.date');
    }

    setDominantColor(color: string){
        this.dominantColor = color;
    }

    parseValue(value: string) {
        super.parseValue(value);
        if(value) {
            let humanDate = /^(\d\d)\/(\d\d)\/(\d\d\d\d)$/g;
            let completeDate = /(\d\d)-(\d\d)-(\d\d\d\d) \d\d:\d\d(:\d\d)*/g;
            if(value.match(humanDate)){
                let match = humanDate.exec(value);
                value = match[3] + '-' + match[2] + '-' + match[1] + ' 00:00:00';
            } else if(value.match(completeDate)) {
                value = value.substring(0, 10) + ' 00:00:00';
            }
        }
        return value;
    }

    public bindFieldChange(): void {
        this.$el.find('.date').on('change.datetimepicker',(e:any) => {
            if(e.date) {
                this.performChange(false, e, false);
            }
        });
    }
}

export default DateField;
