module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="' +
__e( fieldState.getId() ) +
' ">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<div class="input-group phone" data-id="' +
__e( fieldState.getId() ) +
' ">\n    <input id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" type="tel"class="form-control phoneInput changeableField" value="' +
__e( fieldState.getValue() ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'  ' +
__e( fieldState.isReadOnly() ? 'readonly' : '') +
' ><a href="" class="input-group-addon"><span class="glyphicon-earphone glyphicon"></span></a>\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}