module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<div name-id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'"class="input-group date" data-id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" >\n    <input type="text" class="form-control changeableField" value="' +
__e( moment(fieldState.getValue(), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm') ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' readonly><span class="input-group-addon"><span class="glyphicon-calendar glyphicon"></span></span>\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}