'use strict';

class Event {
    public name: string;
    public callbacks: Array<{ func: Function, ref: PortalView }>;

    constructor(name: string) {
        this.callbacks = [];
        this.name = name;
    }

    addCallback(callback: Function, ref: PortalView) {
        //The ref is already listening
        for(let key in this.callbacks) {
            if(this.callbacks[key].ref === ref) {
                return;
            }
        }
        this.callbacks.push({ func: callback, ref: ref });
    }

    removeCallback(ref: PortalView) {
        let i = this.callbacks.length;
        while (i > 0) {
            i--;
            if (this.callbacks[i].ref === ref) {
                this.callbacks.splice(i, 1);
            }
        }
    }
}

abstract class PortalView {
    protected options: any;
    protected template: any;
    public $el: JQuery<HTMLElement>;
    protected parameters: any;
    protected actionMode: string;
    public eventMap: { [key: string]: Event };
    protected isMobile: boolean;

    constructor(options: any) {
        this.parameters = {};
        this.eventMap = {};
        this.options = options;
        this.isMobile = false;
        this.setElement(options.el);
    }

    abstract render(): any;

    public listenTo(object: PortalView, event: string, callback: Function) {
        object.addCallback(event, callback, this);
    }

    public addCallback(eventName: string, callback: Function, ref: PortalView) {
        let callbacks = this.eventMap[eventName];
        if (callbacks === undefined) {
            this.eventMap[eventName] = new Event(eventName);
        }
        this.eventMap[eventName].addCallback(callback, ref);
    }

    public trigger(event: string, ...args: any) {
        if (this.eventMap[event] !== undefined) {
            this.eventMap[event].callbacks.forEach(function (callback) {
                callback.func.apply(callback.ref, args);
            });
        }
    }

    public stopListening(object: PortalView, event?: string) {
        object.removeCallback(this, event);
    }

    public removeCallback(ref: PortalView, event?: string) {
        for (let eventName in this.eventMap) {
            if (event === undefined || eventName === event) {
                this.eventMap[eventName].removeCallback(ref);
            }
        }
    }

    public setElement(element: JQuery<HTMLElement>) {
        this.$el = element;
    }

    public show() {
        if(this.$el) {
            this.$el.show(200);
        }
    }

    public hide() {
        if(this.$el) {
            this.$el.hide();
        }
    }

    public isVisible() {
        return this.$el && this.$el.is(':visible');
    }
}

export { PortalView };