class Column {
    private config: { [propId: string]: any };
    private columnGroup: ColumnGroup;
    private headers: Header;

    constructor(options) {
        this.config = options;
    }

    public getId(): string {
        return this.config.dataIndex;
    }

    public getDatafieldId(): string {
        return this.config.datafieldId;
    }

    public getFormat(): string {
        return this.config.format;
    }

    public setFormat(format: string): void {
        this.config.format = format;
    }

    public isSummable(): boolean {
        return this.config.summable !== false;
    }

    public getLabel(): string {
        return this.config.text;
    }

    public isHidden(): boolean {
        return this.config.hidden === true || this.getWidth() === 0;
    }

    public getCondition(): string {
        return this.config.condition;
    }

    public getExpr(): string {
        return this.config.expr;
    }

    public getSource(): string {
        return this.config.source;
    }

    public labelIsHidden(): boolean {
        return this.config.labelVisible === false;
    }

    public isBanner(): boolean {
        return this.config.banner === true;
    }

    public getWidth(): number {
        return this.config.width;
    }

    public getLabelSeparator(): string {
        return this.config.labelSeparator;
    }

    public getColumnSeparator(): string {
        return this.config.columnSeparator;
    }

    public getEnum(): any {
        throw new Error('enum needs to be retrieved from the datafield');
    }

    public getStyles(): Array<string> {
        return this.config.styles;
    }

    public setColumnGroup(newColGrp: ColumnGroup): void {
        if (newColGrp instanceof ColumnGroup) {
            this.columnGroup = newColGrp;
            if (this.getGroupId() === undefined) {
                this.setGroupId(newColGrp.getId());
            }
        } else {
            throw new Error('The argument must be of type ColumnGroup');
        }
    }

    public getColumnGroup(): ColumnGroup {
        return this.columnGroup;
    }

    public getGroupId(): string {
        return this.config.groupId;
    }

    public setGroupId(id: string): void {
        this.config.groupId = id;
    }

    public getHeaders(): Header {
        return this.headers;
    }
}

class ColumnGroup {
    private columns: Array<Column>;
    private id: string;

    constructor(options) {
        this.columns = [];
        this.id = options.id;
    }

    public addColumn(newColumn: Column) {
        if (newColumn instanceof Column) {
            this.getColumns().push(newColumn);
            newColumn.setColumnGroup(this);
        } else {
            var message = 'The argument must be of type Column';
            throw new Error(message);
        }
    }

    public getColumns(): Array<Column> {
        return this.columns;
    }

    public getId(): string {
        return this.id;
    }

    public setId(id: string): void {
        this.id = id;
    }
}

class Header {
    private columns: Array<Column>;
    private columnsMap: { [colId: string]: Column };
    private datafieldsToColumnIds: { [dfId: string]: string };

    constructor(options) {
        this.columns = [];
        this.columnsMap = {};
        this.datafieldsToColumnIds = {};
        var currentGroup;
        var defaultCounter = 1;

        for (var key in options) {
            var newCol = new Column(options[key]);

            var currentRowGroupId = newCol.getGroupId();
            if (currentRowGroupId === undefined) {
                //assigning to current group or new group
                if (currentGroup === undefined || currentGroup.getId().indexOf('DEFAULT_GROUP_') !== 0) {
                    //No current group and no defined group, we create a default one
                    currentGroup = new ColumnGroup({
                        id: 'DEFAULT_GROUP_' + defaultCounter
                    });
                }
            } else {
                //row has a defined group
                if (currentGroup === undefined) {
                    currentGroup = new ColumnGroup({
                        id: currentRowGroupId
                    });
                } else if (currentGroup.getId() !== currentRowGroupId) {
                    currentGroup = new ColumnGroup({
                        id: currentRowGroupId
                    });
                }
            }
            //Group will get the column and column will be automatically referencing group
            currentGroup.addColumn(newCol);
            this.columns.push(newCol);
            this.columnsMap[newCol.getId()] = newCol;
            this.datafieldsToColumnIds[newCol.getDatafieldId()] = newCol.getId();
        }
    }

    public getDatafieldsToColumnIds(): { [dfId: string]: string } {
        return this.datafieldsToColumnIds;
    }

    public getColumnsGroups(): { [groupId: string]: ColumnGroup } {
        var groups = {};

        var currentCols = this.getColumns();
        for (var key in currentCols) {
            groups[currentCols[key].getGroupId()] = currentCols[key].getColumnGroup();
        }

        return groups;
    }

    public getColumnsGroupsOrdered(): Array<ColumnGroup> {
        var groups = [];
        var lastId;

        var currentCols = this.getColumns();
        for (var key in currentCols) {
            var currentColGroupId = currentCols[key].getGroupId();
            if (lastId === undefined || lastId !== currentColGroupId) {
                groups.push(currentCols[key].getColumnGroup());
                lastId = currentColGroupId;
            }
        }

        return groups;
    }


    public getColumns(): Array<Column> {
        return this.columns;
    }

    public getColumnsMap(): { [colId: string]: Column } {
        return this.columnsMap;
    }
}

export { Header, Column };
