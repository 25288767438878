'use strict';

import DateField from './Date';
let moment = require('moment');

class TimeField extends DateField{
    constructor (options: {[key:string]: any}){
        super(options);
        this.visualDateFormat = 'HH:mm';
    }

    setModelValue (value: string) {
        let currentValue: string;
        if(value.length > '00:00'.length) {
            // We assume that we received a full datetime
            currentValue = value;
        } else {
            // Warning, value is a string. According to momentjs docs, we must
            // not parse a string directly as the result is inconsistent between
            // browsers, locale and timezones.
            // We want to parse the exact format to avoid such problems.
            currentValue = this.fieldState.getValue();
            if(!currentValue){
                //No value in field -> 1899-01-01
                currentValue = '1899-01-01 ';
            }else{
                //Same date, time change
                currentValue = currentValue.substring(0,'1899-01-01 '.length);
            }
            currentValue = currentValue + value;
        }
        var m = moment(currentValue, 'YYYY-MM-DD HH:mm');
        this.fieldState.setValue(m.format('YYYY-MM-DD HH:mm:ss'));
    }
}

export default TimeField;
