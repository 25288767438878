'use strict';

import GroupView from './Group';
const { AbstractTask, Group, Field, FieldInstance, PageInstance, Panel } = require('@sai/configuration');
const { Context, Task, MobileRequest, Server } = require('@sai/protocol');
import LoadingMask from '../LoadingMask';
import FieldView from './Field';
import { PortalView } from '../PortalView';
import ErrorDisplay from '../ErrorDisplay';
import RecordUtils from '../../utils/RecordUtils';

class RecordView extends PortalView {
    public groups : Array<GroupView>;
    private groupsMap: {[groupId:string]: GroupView};
    private contextType : any;
    public domainContext: any;
    private dossierKeys: any;
    private groupsConfig : Array<typeof Group>;
    private taskConfig: typeof AbstractTask;
    private dominantColor: string;
    private displayMode: string;
    private fields: {[propId: string]: FieldView};
    private enabled: boolean;
    private panelId: string;
    private hasPendingFieldChange: boolean;
    private eventsBinded: boolean;
    private fieldChangeCallback: any;
    private fieldChangeErrorCallback: any;
    private hiddenFields: Array<string>;
    private fileUploadCallback: any;

    constructor(options?: any) {
        super(options);
        this.domainContext = options.domainContext;
        this.contextType = options.contextType || 'record';
        this.dominantColor = options.dominantColor;
        this.displayMode = options.displayMode;
        this.hiddenFields = options.hiddenFields;
        this.template = require('../../templates/Record.ejs');
        this.groups = [];
        this.groupsMap = {};
        this.enabled = options.enabled !== undefined ? options.enabled : true;
        this.fileUploadCallback = options.fileUploadCallback;
        if (options.groups) {
            this.setGroups(options.groups);
        }
        this.panelId = options.panelId;
        this.eventsBinded = false;
        this.parameters['displayMode'] = options.displayMode;
        this.fieldChangeCallback = options.fieldChangeCallback;
        this.fieldChangeErrorCallback = options.fieldChangeErrorCallback;
    }

    setKeys(keys: any) {
        this.dossierKeys = keys;
    }

    getKeys() {
        return this.dossierKeys;
    }

    setDominantColor(color) {
        this.options.dominantColor = color;
        if(this.groups.length > 0){
            for(var key in this.groups){
                this.groups[key].setDominantColor(color);
            }
        }
    }

    /**
     * The goal of this function is to update all the field instances with the
     * proper values and positioning
     * @param groups The list of instance groups
     */
    public setValuesAndPositions(groups: Array<typeof Group>, renderModifications?:boolean): void {
        for(let key in groups) {
            let curGrpFields = groups[key].getFields();
            for(let fKey in curGrpFields) {
                this.setField(curGrpFields[fKey], renderModifications);
            }
            if(renderModifications) {
                this.groupsMap[groups[key].getId()].moveFieldsToPositions();
            }
        }
    }

    public getField(id: string): FieldView {
        return this.fields[id];
    }

    public getFieldByDatafieldId(datafieldId : string) : FieldView {
        for(let i in this.fields) {
            let fieldConfig = this.fields[i].getConfig();
            if(fieldConfig.getDatafieldId() === datafieldId) {
                return this.fields[i];
            }
        }
        return undefined;
    }

    setField(field: typeof Field, renderModifications: boolean, discardPositions?: boolean) {
        if(field.getId() === 'EDMSDESCRIPTOR_1') {
            return;
        }
        let fieldView = this.fields[field.getId()];
        if (fieldView) {
            let finalValue;
            if(fieldView.getConfig().getPermanentValue() !== undefined) {
                finalValue = fieldView.getConfig().getPermanentValue();
            } else if(fieldView.getConfig().getLinkedDataField().getPermanentValue() !== undefined) {
                finalValue = fieldView.getConfig().getLinkedDataField().getPermanentValue();
            } else {
                if(this.actionMode === 'newEntry') {
                    finalValue = field.getValue() === '' ? fieldView.getValue() : field.getValue();
                } else {
                    finalValue = field.getValue();
                }
                //finalValue = field.getValue() === '' ? fieldView.getValue() : field.getValue();
            }
            fieldView.setModelValue(fieldView.parseValue(finalValue));
            //TODO : check if still needed
            //fieldView.setNotes(Note.buildNoteArray(field.getConfig().notes));
            if(!discardPositions) {
                fieldView.setEnabled(field.isEnabled() && this.enabled);
                fieldView.setVisible(field.isVisible(this.displayMode));
                fieldView.setReadOnly(field.isReadOnly() ? 'true' : 'false');
            }
            fieldView.setLabel(field.getLabel());
            if(renderModifications) {
                fieldView.render();
                fieldView.onDOMUpdated();
            }
        } else {
            throw new Error('Unknown field in screen ' + field.getId() + ' with datafield ' + field.getDatafieldId());
        }
    }

    setGroups(groupsConfig) {
        this.groupsConfig = groupsConfig;
        this.clearCurrentGroups();

        for (var key in this.groupsConfig) {
            var newGroup = this.groupsConfig[key];
            var newView = new GroupView({
                displayMode: this.parameters['displayMode'],
                groupConfig: newGroup,
                taskId: newGroup.getPanel().getParentTask().getId(),
                dominantColor: this.dominantColor,
                domainContext: this.domainContext,
                enabled: this.enabled,
                hiddenFields: this.hiddenFields,
                fileUploadCallback: this.fileUploadCallback
            });
            this.groups.push(newView);
            this.groupsMap[newView.getConfig().getId()] = newView;
            Object.assign(this.fields, newView.getFields(function(){return true;}).reduce(function(acc, cur, i) {
                acc[cur.getState().getId()] = cur;
                return acc;
            }, {}));
        }
    }

    clearCurrentGroups() {
        this.groups = [];
        this.fields = {};
    }

    render() : any {
        this.$el.addClass('recordView');
        this.$el.addClass(this.parameters['displayMode']);

        this.$el.html(this.template());

        //Removing all groups before re-rendering
        this.$el.find('.innerRecord').empty();

        if (this.groups && this.groups.length > 0) {
            this.renderGroups();
            
            var allClickableFields = this.getFields(function (field: FieldView) {
                return field.canBeClick();
            });
            for (var key in allClickableFields) {
                this.listenTo(allClickableFields[key], 'fieldClick', this.onFieldClick.bind(this));
            }

            var allChangeableFields = this.getFields(function (field: FieldView) {
                return field.canBeChange();
            });

            for (var key2 in allChangeableFields) {
                this.listenTo(allChangeableFields[key2], 'fieldChange', this.onFieldChange.bind(this));
            }

            var allContextCallable = this.getFields(function (field: FieldView) {
                let type = field.getConfig().getLinkedDataField() === undefined ? 'Text' : field.getConfig().getObjectTypeFromFormatPresentation();
                return type === 'Couple' || type === 'ListCouple';
            });

            for (var coupleKey in allContextCallable) {
                this.listenTo(allContextCallable[coupleKey], 'panel.onPrepareContext', this.onContextRecordRequest.bind(this));
                this.listenTo(allContextCallable[coupleKey], 'panel.onPrepareScriptContext', this.onContextScriptRequest.bind(this, false, true));
            }

            /*var allContextClickable = this.getFields(function (field: FieldView) {
                let type = field.getConfig().getObjectTypeFromFormatPresentation();
                return type === 'Link';
            });
            if(!this.eventsBinded) {
                this.eventsBinded = true;
                for(var linkKey in allContextCallable) {
                    this.listenTo(allContextClickable[linkKey], 'linkclicked', this.onLinkClick.bind(this));
                }
            }*/
        }

        var fields = this.getFields(function(){return true;});
        for(var fieldId in fields){
            this.listenTo(fields[fieldId], 'requestFocus', this.onRequestFocus.bind(this));
            this.listenTo(fields[fieldId], 'restrictionRequest', this.onRestrictionRequest.bind(this));
        }
    }

    private onRestrictionRequest(props) {
        props.fieldChange = this.hasPendingFieldChange;
    }

    /*private onLinkClick(config: typeof Field) {
        let datafield = config.getLinkedDataField();
        let notification = datafield.getNotificationOnClick();
        if(notification) {
            let taskMap = { task: undefined };
            this.trigger('record.taskContext', taskMap);
            let notificationManager = new NotificationManager({
                currentTaskContext: taskMap.task,
                domainContext: this.domainContext,
                contextFiller: this.fillNotificationOnClickContext.bind(this, config)
            })
            notificationManager.handle(notification);
        }
    }*/

    private fillNotificationOnClickContext(field: typeof Field, context: {[propId: string]: string}) {
        this.trigger('record.onPrepareScript', context, this.panelId, false);
        context['currentFieldId'] = field.getId();
        context['currentDatafieldId'] = field.getDatafieldId();
    }

    onRequestFocus(evt){
        /*if(DeviceUtils.isMobile()){
            if($(evt.delegateTarget).offset().top > 0){
                this.$el.parent().scrollTop($(evt.delegateTarget).offset().top - this.$el.offset().top - 10);
            }
        }*/
    }

    onContextRecordRequest(context: typeof Context, needsAllPanels: boolean) {
        context.setContextId(this.contextType);
        if(this.contextType === 'page') {
            var values = this.getFieldsValues();
            context.addFields(values);
            for(var key in this.dossierKeys){
                context.addParameter(key, this.dossierKeys[key]);
            }
        } else {
            if(!this.panelId){ throw new Error('Missing panelId in record definition');}
            if(needsAllPanels) {
                //We trigger the upper level, as there might be multiple panels to send
                this.trigger('record.onPrepareContext', context, this.panelId);
            } else {
                var values = this.getFieldsValues();
                context.addPanelFields(values, this.panelId);
            }
        }
    }

    public getScriptContext(withDollar: boolean): {[propId:string]: string} {
        if(withDollar === undefined) {
            withDollar = true;
        }
        let context = {};
        let fields = {};
        var values = this.getFieldsValues();
        for(let id in values) {
            fields[values[id].key] = values[id].value;
        }
        let allFields: Array<FieldView> = this.getFields(function(){ return true;});
        for(let id in allFields) {
            let config: typeof Field = allFields[id].getConfig();
            let datafieldId;
            if(config.isCouple() && config.isCoupleKey()) {
                datafieldId = (withDollar && config.getValue().indexOf('$') === -1 ? '$' : '') + config.getValue();
            } else {
                datafieldId = (withDollar && config.getDatafieldId().indexOf('$') === -1? '$' : '') + config.getDatafieldId();
            }
            if(datafieldId.indexOf('.') < 0) {
                context[datafieldId] = fields[config.getId()];
            } else {
                let splitted = datafieldId.split('.');
                let root = context;
                for(let i in splitted) {
                    let child = splitted[i];
                    if(root[child] === undefined) {
                        root[child] = {};
                    }
                    if(splitted.indexOf(child) === splitted.length - 1) {
                        root[child] = fields[config.getId()];
                    } else {
                        root = root[child];
                    }
                }
            }

            context[(withDollar ? '$' : '') + config.getId()] = fields[config.getId()];
        }

        this.trigger('record.onPrepareScriptContext',context, withDollar);

        return context;
    }

    onContextScriptRequest(needsAllPanels: boolean, needsDollarsPrefix: boolean, context: {[propId: string]: string}) {
        if(this.contextType === 'page') {
            Object.assign(context, this.getScriptContext(needsDollarsPrefix));

        } else {
            if(!this.panelId){ throw new Error('Missing panelId in record definition');}
            if(needsAllPanels) {
                //We trigger the upper level, as there might be multiple panels to send
                this.trigger('record.onPrepareScript', context, this.panelId, needsDollarsPrefix);
            } else {
                Object.assign(context, this.getScriptContext(needsDollarsPrefix));
            }
        }
    }

    setRequestRecordContext(request: typeof MobileRequest, sendAllPanels: boolean) {
        var recordContext = new Context({});
        this.onContextRecordRequest(recordContext, sendAllPanels);
        if(this.contextType === 'page'){
            request.setPageContext(recordContext);
            for(var key in this.dossierKeys){
                recordContext.addParameter(key, this.dossierKeys[key]);
            }
            let screen = recordContext.attributes.screen;
            for (let i = screen.length - 1; i >= 0; i--) {
                if (screen[i].key === 'FM_PAGE_VERSION') {
                    screen.splice(i, 1);
                    break;
                }
            }
        }else{
            request.setRecordContext(recordContext);
        }
    }

    setRequestKeyContext(request: typeof MobileRequest) {
        let taskKeyContext = new Context({});
        this.trigger('record.onPrepareKeyContext', taskKeyContext);
        request.setKeyContext(taskKeyContext);
    }

    onFieldClick(fieldData, sendAllPanels: boolean) {
        /*var recordValues = this.getFieldsValues();
        //Allow to modify the values if needed before sending them to the server
        let clickRequest = new FieldClickRequest(this.domainContext, this.options.taskId);
        clickRequest.setFieldId(fieldData.name);
        this.setRequestRecordContext(clickRequest, sendAllPanels);
        this.setRequestKeyContext(clickRequest);

        let loadingReqId;
        LoadingMask.requestLoading('Chargement...')
            .then((reqId) => {
                loadingReqId = reqId;
                return Server.performRequest(clickRequest);
            })
            .then(this.onFieldResult.bind(this, 'Click', sendAllPanels))
            .catch(App.displayErrorMessage)
            .then(function(){ LoadingMask.hide(loadingReqId);});*/
    }

    flattenValue(value: any) : string {
        return typeof value === 'object' ? value.join(',') : value;
    }

    onFieldChange(fieldData: typeof FieldInstance, sendAllPanels: boolean, promises: Array<Promise<any>>) {
        // Don't call the onFieldChange if the field is not enabled
        if(fieldData.isEnabled()) {
            this.hasPendingFieldChange = true;
            //Allow to modify the values if needed before sending them to the server
            let changeRequest = new Task.FieldChangeRequest(this.domainContext, this.options.taskConfig.getId());
            changeRequest.setFieldId(fieldData.getId());
            changeRequest.setInitialValue(this.flattenValue(fieldData.getInitialValue()));
            changeRequest.setLastNonEmptyValue(this.flattenValue(fieldData.getLastNonEmptyValue()));
            changeRequest.setLastValue(this.flattenValue(fieldData.getLastValue()));

            this.setRequestRecordContext(changeRequest, sendAllPanels);
            this.setRequestKeyContext(changeRequest);
            promises.push(new Promise((accept, reject) => {
                //let loadingReqId;
                //LoadingMask.requestLoading('Chargement...')
                    //.then((reqId) => {
                        //loadingReqId = reqId;
                    Server.performRequest(changeRequest)
                    //})
                    .then((result) => {
                        //As some fields might reset their size, we need to lock the record size
                        this.lockRecordSize();
                        //We apply the new fields
                        this.onFieldResult('Change', sendAllPanels, result, fieldData);
                        //And unlock back the size
                        this.unlockRecordSize();
                        if(this.fieldChangeCallback) { this.fieldChangeCallback(); }
                        accept(undefined);
                    })
                    .catch((error) => {
                        /*ErrorDisplay.render({
                            hideTitle : false,
                            errorTitle : error.title,
                            errorText : error.body,
                            errorDetail : error.expert ? error.expert : ''
                        });*/
                        if(this.fieldChangeErrorCallback) { this.fieldChangeErrorCallback(error); }
                        reject(error);
                    })
                    .then(() => {
                        this.hasPendingFieldChange = false;
                        //LoadingMask.hide(loadingReqId);
                    })
                    .catch(reject);
            }));
        }
    }

    private lockRecordSize(): void {
        this.$el.css('height', this.$el.height());
    }

    private unlockRecordSize(): void {
        this.$el.css('height', '');
    }

    onFieldResult(type, sendAllPanels: boolean, result, fieldData: typeof FieldInstance) {
        var allFields = this.getFields(function (field: any) {
            return field['canBe' + type]();
        });

        for (var key in allFields) {
            this.stopListening(allFields[key], 'field' + type);
        }
        if(this.contextType === 'page') {
            let newPage = new PageInstance(result.screen, this.options.taskConfig, null, this.domainContext);
            this.setValuesAndPositions(newPage.getGroups());
            this.render();
        } else {
            if(sendAllPanels) {
                this.trigger('record.updateRecordsRequest', result.task);
            } else {
                let curPan = new Panel(result.task.screen[0], this.taskConfig, this.domainContext);
                this.setValuesAndPositions(curPan.getGroups());
                this.render();
            }
        }
        let fieldView = this.getField(fieldData.getId());
        let nextField = this.getNextField(fieldView);
        if(nextField !== undefined) {
            nextField.focus();
        }
    }

    public getGroup(groupId: string) {
        return this.groupsMap[groupId];
    }

    public getGroups() : Array<GroupView> {
        return this.groups;
    }

    renderGroups() {
        var holder = this.$el.find('.innerRecord');
        for (var key in this.groups) {
            var currentGroup = this.groups[key];
            var newGroupEl = $('<fieldset>').appendTo(holder);
            newGroupEl.addClass('col-md-' + RecordUtils.getGridPosFromValue(currentGroup.getConfig().getPosition('tablet').width));
            newGroupEl.addClass('col-sm-' + RecordUtils.getGridPosFromValue(currentGroup.getConfig().getPosition('phone').width));
            currentGroup.setElement(newGroupEl);
            currentGroup.render();
        }
        this.moveGroupsToPositions();
    }

    moveGroupsToPositions() {
        /*for (var key in this.groups) {
            var currentGroup = this.groups[key];
            currentGroup.$el.css(FieldFactory.locateGroupinPanel(this, currentGroup, this.options.displayMode));
        }*/
    }

    getFieldsValues() {
        var finalOutput = [];
        for (var grpKey in this.groups) {
            var currentGrpOutput = this.groups[grpKey].getFieldsValues();
            for (var key in currentGrpOutput) {
                let curField = currentGrpOutput[key];
                var newField = {
                    key: key,
                    value: curField.value,
                    enabled: curField.enabled,
                    visible: curField.visible,
                    humanValue: curField.humanValue
                };
                if (currentGrpOutput[key].notes) {
                    newField['notes'] = currentGrpOutput[key].notes;
                }
                finalOutput.push(newField);
            }
        }
        return finalOutput;
    }

    getGroupsBoundaries() {
        var finalBounds = {
            min: Number.MAX_VALUE,
            max: 0
        };

        for (var grpKey in this.groups) {
            var current = this.groups[grpKey];
            finalBounds.min = Math.min(finalBounds.min, current.getFieldMinLeft());
            finalBounds.max = Math.max(finalBounds.max, current.getFieldMaxLeft());
        }

        return finalBounds;
    }

    getFields(filter) : Array<FieldView>{
        var allFields: Array<any> = [];
        for (var grpKey in this.groups) {
            allFields = allFields.concat(this.groups[grpKey].getFields(filter));
        }
        return allFields;
    }

    setDisplayMode(mode: string) {
        this.parameters['displayMode'] = mode;
    }

    resize(mode: string, optHeight: number) {}

    getMaxHeight() : number {
        let maxHeight : number = 0;
        for (var grpKey in this.groups) {
            let current = this.groups[grpKey];
            let curHeight = current.$el.offset().top + current.$el.outerHeight();
            if(curHeight > maxHeight) {
                maxHeight = curHeight;
            }
        }
        return maxHeight;
    }

    getTotalHeight() : number {
        let totalHeight : number = 0;
        for (var grpKey in this.groups) {
            let current = this.groups[grpKey];
            totalHeight += current.$el.outerHeight(true);
        }
        return totalHeight;
    }

    getMaxWidth() : number {
        let maxWidth : number = 0;
        for (var grpKey in this.groups) {
            let current = this.groups[grpKey];
            if (current.$el.width() > maxWidth) {
                maxWidth = current.$el.width();
            }
        }
        return maxWidth;
    }

    setEnabled(enabled: boolean) {
        this.enabled = enabled;
    }

    public validate() {
        let hasInvalid = false;
        for(let key in this.fields) {
            if(!this.fields[key].validate()) {
                hasInvalid = true;
            }
        }
        return !hasInvalid;
    }

    public hasInvalidFields() {
        for(let key in this.fields) {
            if(!this.fields[key].getState().isUserValid()) {
                return true;
            }
        }
        return false;
    }

    public moveToField(fieldId: string) {
        if(fieldId) {
            let field = this.fields[fieldId];
            this.$el.animate({
                scrollTop: field.$el.offset().top
            });
        }
    }

    public getInvalidField() {
        for(let key in this.fields) {
            if(!this.fields[key].getState().isUserValid()) {
                return key;
            }
        }
        return null;
    }

    private getNextField(currentField: FieldView) : FieldView {
        let fieldList = this.getFields(null);
        let nextFieldIndex = fieldList.indexOf(currentField) + 1;
        let nextField = undefined;
        if(nextFieldIndex > 0 && nextFieldIndex < fieldList.length) {
            while(nextFieldIndex < fieldList.length) {
                nextField = fieldList[nextFieldIndex];
                if(nextField.$el.is(':visible')) {
                    break;
                } else {
                    nextFieldIndex ++;
                }
            }
        }
        if(nextFieldIndex === 0 || nextFieldIndex === fieldList.length) {
            // Next field not found --> keep the current field
            nextField = currentField;
        }
        return nextField;
    }
}

export default RecordView;
