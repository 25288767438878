'use strict';

import CoupleField from './Couple';

class ListCoupleField extends CoupleField {
    public isMultiple(): boolean {
        return true;
    }

    protected getRenderingModel(): { [key: string]: any } {
        let mod = super.getRenderingModel();
        mod.associatedLabels = this.associatedLabels;
        return mod;
    }
}

export default ListCoupleField;