'use strict';

import FieldView from '../Field';
import Inputmask from 'inputmask';

class TextField extends FieldView {
    public onDOMUpdated() {
        super.onDOMUpdated();
        if(this.getValue() !== '') {
            this.$el.find('label').addClass('active');
        }
        if(this.fieldConfig.getLinkedDataField()) {
            let specialMask = this.fieldConfig.getLinkedDataField().getSpecialMask();
            if (specialMask !== undefined && specialMask.length > 0) {
                let field = this.getFieldElement();
                if (field.length > 0) {
                    var im = new Inputmask(specialMask);
                    im.mask(field[0]);
                }
            }
        }
    }
}

export default TextField;
