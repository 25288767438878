'use strict';

import intlTelInput from 'intl-tel-input';
import TextView from './Text';

class PhoneField extends TextView {
    private phoneEl: any;
    private iti: any;

    onDOMUpdated () {
        super.onDOMUpdated();

        this.phoneEl = this.$el.find('.phoneInput');
        if(this.phoneEl.length > 0) {
            this.iti = window.intlTelInput(this.phoneEl[0], {
                //autoFormat: true,
                initialCountry: 'ch',
                preferredCountries: ['ch', 'de', 'fr'],
                formatOnDisplay: true
            });
            this.phoneEl.on('countrychange', () => {
                this.onPhoneNumberChange();
            });
            var me = this;
            this.phoneEl.on('change', () => {
                var number = me.phoneEl.val();
                var classf = me.$el.find('.iti__selected-flag > .iti__flag').attr('class');
                var flag = classf.slice(-2);
                var formattedNumber = (window.intlTelInputUtils as any).formatNumber(number, flag, window.intlTelInputUtils.numberFormat.NATIONAL);
                me.phoneEl.val(formattedNumber);
                this.setPhoneLink();
            });
            this.setPhoneLink();
        }
    }

    setPhoneLink() {
        var number = this.phoneEl.val();
        var classf = this.$el.find('.iti__selected-flag > .iti__flag').attr('class');
        var flag = classf.slice(-2);
        var formattedNumber = (window.intlTelInputUtils as any).formatNumber(number, flag, window.intlTelInputUtils.numberFormat.INTERNATIONAL);
        this.$el.find('a.input-group-addon').attr('href', 'tel:' + formattedNumber)
    }

    onPhoneNumberChange() {
        this.setModelValue(this.iti.getNumber());
    }

    getFieldElement() {
        return this.$el.find('.phoneInput');
    }
}

export default PhoneField;
