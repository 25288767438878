import { PortalView } from '../PortalView';
import { Context } from '@sai/protocol';
import I18NUtils from '../../utils/I18NUtils';
import * as _ from "lodash";

export default class RequestView extends PortalView {
    public static key: number;
    private id: number;
    private label: string;
    private values: typeof Context;
    private allowRemoval: boolean;
    private lang: string;
    private validationTitle: string;
    private initialGroupsConfig: Array<any>;

    constructor(options: { label: string, allowRemoval: boolean, values: typeof Context, [key:string]: any  }) {
        super(options);
        this.label = options.label;
        this.values = options.values;
        this.id = ++RequestView.key;
        this.template = require('../../templates/initialRequests/InitialRequest.ejs');
        this.allowRemoval = options.allowRemoval;
        this.lang = options.lang;
    }

    public render(): void {
        this.$el.addClass(['initial-request', 'col-lg-3', 'col-md-4', 'col-sm-6']);
        this.$el.html(this.template({ request: this, add_label: I18NUtils.getText('subscribe_new_request', this.lang, {})  }));
    }

    public getId(): number {
        return this.id;
    }

    public getLabel(): string {
        return this.label;
    }

    public getValues(): typeof Context {
        return this.values;
    }

    public setLabel(label: string) : void {
        this.label = label;
    }

    public setValues(values: typeof Context): void {
        this.values = values;
    }

    public canBeRemoved(): boolean {
        return this.allowRemoval
    }

    public setValidationTitle(title:string) {
        this.validationTitle = title;
    }

    public getInitialGroupsConfig(): Array<any> {
        return this.initialGroupsConfig;
    }

    public setInitialGroupsConfig(groupConfig: Array<any>) {
        this.initialGroupsConfig = _.cloneDeep(groupConfig);
    }
}

RequestView.key = 0;