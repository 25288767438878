module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a class="" data-id="' +
__e( fieldState.getId() ) +
' " ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' ><span class="glyphicon-play glyphicon"></span>' +
__e( fieldState.getLabel() ) +
'</a>\n';

}
return __p
}