'use strict';

import { PortalView } from '../PortalView';
const { Field, FieldInstance, FieldValidityLevel } = require('@sai/configuration');
const { DomainContext } = require('@sai/protocol');
const moment = require('moment');
import * as _ from 'lodash';
import ScriptUtils from '../../utils/ScriptUtils';
import { threadId } from 'worker_threads';

const fieldsTpls = {
    'Button': require('../../templates/fields/Button.ejs'),
    'Checkbox': require('../../templates/fields/Checkbox.ejs'),
    'Couple': require('../../templates/fields/Couple.ejs'),
    'Date': require('../../templates/fields/Date.ejs'),
    'DateTime': require('../../templates/fields/DateTime.ejs'),
    'GEDImage': require('../../templates/fields/Text.ejs'),
    'GEDDocument': require('../../templates/fields/GedDocument.ejs'),
    'Hidden': require('../../templates/fields/Hidden.ejs'),
    'Integer': require('../../templates/fields/Integer.ejs'),
    'Link': require('../../templates/fields/Link.ejs'),
    'ListCouple': require('../../templates/fields/ListCouple.ejs'),
    'ListEnum': require('../../templates/fields/Enum.ejs'),
    'Financial': require('../../templates/fields/Number.ejs'),
    'Float': require('../../templates/fields/Float.ejs'),
    'Password': require('../../templates/fields/Password.ejs'),
    'Phone': require('../../templates/fields/Phone.ejs'),
    'Enum': require('../../templates/fields/Enum.ejs'),
    'EnumIcon': require('../../templates/fields/EnumIcon.ejs'),
    'Text': require('../../templates/fields/Text.ejs'),
    'MultiLineString': require('../../templates/fields/TextArea.ejs'),
    'Time': require('../../templates/fields/Time.ejs'),
    'SystemField': require('../../templates/fields/SystemField.ejs'),
    'Filter': require('../../templates/fields/Filter.ejs'),
    'GPSCoordinates': require('../../templates/fields/GPSCoordinates.ejs'),
    'PrintSettings': require('../../templates/fields/PrintSettings.ejs'),
    'File': require('../../templates/fields/File.ejs')
}

interface FieldLocationProperties {
    fieldWidth?: string,
    labelVisible?: boolean,
    height?: number,
    fieldHeight?: number,
    top?: number,
    fieldLeft?: string,
    labelLeft?: string
};

class FieldView extends PortalView {
    public fieldConfig: typeof Field;
    public fieldState: typeof FieldInstance;
    protected displayMode: string;
    protected locationProperties: FieldLocationProperties;
    protected domainContext: typeof DomainContext;
    protected blocked: boolean;
    protected rendered: boolean;
    protected scriptContext: any;
    protected visible: boolean;
    protected fileUploadCallback: any;

    /**
     * Create the field model based on the given field config set as options.
     * If you want to process more properties than the base ones, override this
     * function and call the super function to process both base and specific
     * ones.
     *
     * @param {type} options The properties of the field
     * @returns {undefined}
     */
    constructor(options: {config: typeof Field, displayMode?: string, domainContext: typeof DomainContext, visible?: boolean, fileUploadCallback?: any}) {
        super(options);
        this.blocked = false;
        this.fieldConfig = options.config;
        this.visible = options.visible !== undefined ? options.visible : true;
        this.fieldState = FieldInstance.fromField(options.config);
        this.scriptContext = {
            '$CURRENT_CHAIN': options.config.getTask().getId().substring(0,3),
            '$CURRENT_USER_ID': 'someuser',
            '$LOCAL_DATE': moment().format('YYYY-MM-DD 00:00:00'),
            '$LOCAL_DATETIME': moment().format('YYYY-MM-DD HH:mm:ss'),
            'getUpperCase' : function(str){return str.toUpperCase();},
            '$CURRENT_LANGUAGE' : 'FR'
        };
        if(this.fieldConfig.getValue() === '') {
            let defaultValue = this.fieldConfig.getDefaultValue();
            if(defaultValue) {
                if(defaultValue.includes('$')) {
                    //Script, we need to evaluate
                    defaultValue = this.evalDefaultValue(defaultValue);
                }
                this.fieldState.setValue(this.parseValue(defaultValue));
            }
        } else {
            this.fieldState.setValue(this.parseValue(this.fieldConfig.getValue()));
        }

        let permanentValue = this.fieldConfig.getPermanentValue();
        if(permanentValue !== undefined) {
            if(permanentValue.includes('$')) {
                //Script, we need to evaluate
                permanentValue = ScriptUtils.evalInContext(permanentValue, this.scriptContext);
            }
            this.fieldState.setValue(permanentValue);
            this.fieldState.setEnabled(false);
        }

        this.displayMode = options.displayMode || 'tablet';

        this.domainContext = options.domainContext;

        let type = this.fieldConfig.getLinkedDataField() === undefined ? 'Text' : this.fieldConfig.getObjectTypeFromFormatPresentation();
        if (!type) {
            let errorMsg = 'no format given to field through datafield';
            console.error(errorMsg);
            throw new Error(errorMsg);
        } else {
            this.template = fieldsTpls[type];
        }

        if (!this.template) {
            console.error('Missing template : ' + 'templates/fields/' + type + '.ejs in the requirements');
        }
        this.locationProperties = {};
        this.fileUploadCallback = options.fileUploadCallback;
    }

    protected evalDefaultValue(defaultValue: string) : any {
        //Script, we need to evaluate
        return ScriptUtils.evalInContext(defaultValue, this.scriptContext);
    }

    protected checkForSelectBlocked(selectEl, evt) {
        //This allows the combo to always be opened after every other events
        //If the user fill a fields with on field change, and exists the field
        //by clicking on the couple combo, then we've have an inconsistent state
        //as the getgriddata will be performed with the wrong context. The field
        //might not even be selectable anymore. Thus we make sure that the opening
        //is triggered after the fieldchange.
        //We request some info on the actual restrictions
        if(!this.blocked) {
            evt.preventDefault();
            this.blocked = true;
            setTimeout(() => {
                selectEl.select2('open');
            }, 10);
        } else {
            //Now that we're at the end of the event pile we can check if
            //we allow the opening or not
            this.blocked = false;
            let restrictionProps = {
                fieldChange: false
            };
            this.trigger('restrictionRequest', restrictionProps);
            if(restrictionProps.fieldChange) {
                evt.preventDefault();
            }
        }
    }

    /**
     * Allows pre parsing of the server value in case we've to make modifications
     * @param {type} value
     * @returns {String}
     */
    public parseValue (value){
        // Handle for the couples where the dataFieldId is the foreign key instead of the dataFieldId from the config
        let suffix = this.fieldConfig.isCouple() && this.fieldConfig.isCoupleKey() ? this.fieldConfig.getLinkedCouple().getConfig().keyVar :
            this.fieldConfig.getLinkedDataField().getId();
        if(value === '$' + suffix) {
            value = '';
        }
        return value || '';
    }

    protected getRenderingModel(): {[key:string]: any} {
        return {
            moment: moment,
            //server: Server,
            fieldState: this.fieldState,
            displayMode: this.displayMode,
            name: this.fieldState.getId(),
            value: this.fieldState.getValue(),
            position: this.fieldState.getPosition(this.displayMode),
            maxLength: this.getConfig().getLinkedDataField().getMaxLength()
        };
    }

    /**
     * Renders the field based on its inner template and listens to field value
     * changes.
     *
     * @returns {undefined}
     */
    render () : any {
        this.$el.toggleClass('field-enabled', this.fieldState.isEnabled());
        this.$el.toggleClass('field-disabled', !this.fieldState.isEnabled());
        this.$el.toggleClass('field-required', this.fieldState.isRequired(this.displayMode));
        this.$el.toggleClass('field-invalid', !this.fieldState.isUserValid());
        this.$el.addClass('input-field');
        this.$el.attr('data-id', this.fieldConfig.getId());
        if(this.fieldState.isVisible(this.displayMode) && this.visible){
            this.rendered = true;
            /*let renderingModel = this.getRenderingModel();
            renderingModel.underscore = _;*/
            let tplHtml = this.template(this.getRenderingModel());
            this.$el.html(tplHtml);
            this.$el.addClass('recordField');
            this.bindFieldChange();
            let files = this.fieldState.getNotes();
            if (files.length > 0) {
                // Check if the notes contain files
                let noteId = 0;
                let hasFile = false;
                for (noteId = 0; noteId < files.length; noteId++) {
                    if (files[noteId].getNoteText() !== undefined || files[noteId].getLastFileIndex() >= 0) {
                        hasFile = true;
                        break;
                    }
                }

                /*if (hasFile) {
                    var button = this.$el.find('.note-download');
                    button.addClass('displayed');
                    button.on('click',this.downloadDocument.bind(this));
                }*/
            }
            this.$el.find('label').toggleClass('hiddenLabel', !this.fieldState.isLabelVisible(this.displayMode));
            if (this.fieldState.getPosition(this.displayMode).width < 1) {
                this.$el.addClass('label-only');
                this.$el.find('.changeableField').css('display','none');
            }
            this.addFieldHelp();
        }else{
            this.rendered = false;
            this.$el.hide();
        }
    }

    public bindFieldChange(): void {
        this.$el.find('.changeableField').change((e:any) => {
            this.performChange(false, e, false);
        });
    }

    public performChange(forced: boolean, event: JQueryEventObject, fromServer?: boolean): Promise<void[]> {
        if(forced === undefined) {
            forced = false;
        }
        let value;
        // If the value is from the server, get the value from the fieldState instead of using the UI
        if(!fromServer) {
            value = this.getFieldValue();
        } else {
            value = this.fieldState.getValue();
        }
        this.onBeforeFieldChange(fromServer);
        this.setModelValue(this.parseValue(value));
        let promises: Array<Promise<any>> = [];
        this.validate();
        if (this.fieldState.getValue() !== this.fieldState.getLastValue() || forced) {
            if(this.canBeChange()) {
                this.onFieldChange(promises);
            }
            this.$el.trigger('SAIFieldUpdate');
        }
        return Promise.all(promises);
    }

    public onBeforeFieldChange(fromServer: boolean): void {
        //Nothing to do here for now
    }

    getFieldValue(): boolean | string | number | string[] {
        return this.$el.find('.changeableField').val();
    }

    /*downloadDocument (){
        var nd = new NoteDownload(this.fieldState.getNotes());
        nd.display();
    }*/

    onRequestFocus (evt){
        this.trigger('requestFocus', evt);
    }

    /**
     * Sets the given value as the new value for this field. This will also
     * make modifications on last and lastNonEmpty values. Override this function
     * if the value must be processed before setting it.
     *
     * @param {type} field The input field that holds the value
     * @param {type} value The new official inner value of the field
     *
     * @returns {undefined}
     */
    setModelValue (value) {
        this.fieldState.setValue(value);
    }

    setNotes(notes) {
        this.fieldState.setNotes(notes);
    }

    setVisible(visible) {
        this.fieldState.setVisible(visible);
    }

    setLabel(label: string) {
        //if we detected a real change of the label we accept it
        if(label && label !== this.fieldState.getId() && label !== this.fieldState.getDatafieldId()) {
            this.fieldState.setLabel(label);
        }
    }

    isVisible(): boolean {
        return this.fieldState.isVisible(this.displayMode);
    }

    setEnabled(enabled) {
        this.fieldState.setEnabled(enabled);
    }

    isEnabled() {
        return this.fieldState.isEnabled();
    }

    setReadOnly(readOnly:string) {
        this.fieldState.setReadOnly(readOnly);
    }
    /**
     * This function is called after rendering and inserting the field in the
     * DOM. This allows post processing that requires items to be in the real
     * DOM.
     *
     * @returns {undefined}
     */
    onDOMUpdated () {
        this.getFocusableElement().on('focus', this.onRequestFocus.bind(this));
        let curValue = this.getValue();
        if (curValue) {
            this.$el.find('label').addClass('active');
        }
    }

    /**
     * @param {Object} evt The click event sent from the input
     * @triggers {fieldClick} so that the system knows the field has been clicked
     *
     * @returns {undefined}
     */
    onFieldClick (evt) {
        this.trigger('fieldClick', this.fieldState, this.fieldConfig.canSendAllPanels());
    }

    /**
     * @param {Object} evt The change event sent from the input
     * @triggers {fieldChange} so that the system knows the field's value has changed
     *
     * @returns {undefined}
     */
    onFieldChange (promisesArray) {
        this.trigger('fieldChange', this.fieldState, this.fieldConfig.canSendAllPanels(), promisesArray);
    }

    /**
     * @returns Current value of the field
     */
    getValue () {
        // Handle for the couples where the dataFieldId is the foreign key instead of the dataFieldId from the config
        let suffix = this.fieldConfig.isCouple() && this.fieldConfig.isCoupleKey() ? this.fieldConfig.getLinkedCouple().getConfig().keyVar :
            this.fieldConfig.getLinkedDataField().getId();
        let value = this.fieldState.getValue();
        if(value === '$' + suffix) {
            return '';
        } else {
            return typeof value === 'object' ? value.join(',') : value;
        }
    }

    /**
     * @returns First value of the field at creation
     */
    getInitialValue () {
        let value = this.fieldState.getInitialValue();
        return typeof value === 'object' ? value.join(',') : value;
    }

    /**
     * @returns The previous value before change. Empty at creation
     */
    getLastValue () {
        let value = this.fieldState.getLastValue();
        return typeof value === 'object' ? value.join(',') : value;
    }

    /**
     * @returns The first value that is not of length 0 after trim. Empty otherwise
     */
    getLastNonEmptyValue () {
        let value = this.fieldState.getLastNonEmptyValue();
        return typeof value === 'object' ? value.join(',') : value;
    }


    getHumanValue () {
        let value = this.fieldState.getValue();
        if(!value) { 
            return value;
        } else {
            value = value + '';
        }
        let currentFormat = this.getConfig().getLinkedDataField().getFormat();
        if (currentFormat === 'Number' || currentFormat === 'Integer') {
            var cleanedValue = value.replace(/'/g, '');
            var final = parseFloat(cleanedValue);
            return final.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
        } else if(currentFormat === 'Date'){
            if(value === ''){
                return value;
            }else{
                return moment(value, 'YYYY-MM-DD HH:mm:ss').format('L');
            }
        } else if(currentFormat === 'DateTime'){
            if(value === ''){
                return value;
            }else{
                return moment(value, 'YYYY-MM-DD HH:mm:ss').format('L HH:mm');
            }
        } else {
            return value;
        }
    }

    /**
     * Notify the field that it has been resized. Override to make special
     * DOM modifications that would be required after resize.
     *
     * @param {type} newSize Object containing 'width' and 'height' properties
     * @returns {undefined}
     */
    onSizeUpdated (newSize) {
        /*
         * Nothing to do in the abstract class
         */
    }

    /**
     * Defines if the parametrage specifies that the server must be called on click
     * @returns {Boolean} true if the server must be called, false otherwise
     */
    canBeClick () {
        return this.fieldConfig.canCallOnClick();
    }

    /**
     * Defines if the parametrage specifies that the server must be called on value change
     * @returns {Boolean} true if the server must be called, false otherwise
     */
    canBeChange () {
        return this.fieldConfig.canCallOnValidate();
    }

    renderPosition (){
        /*
         * Depending of the mode, we set the css on various entries
         */
        var loc = this.locationProperties;
        var display = this.displayMode;
        var props = this.fieldState.getPosition(this.displayMode);

        if(props.visible === false){
            this.$el.hide();
        } else {
            let coupleConfig = this.getConfig().getLinkedCouple();
            // If the field is not part of a couple or if is is not a couple with fusion or if it is the label part of the fusionned couple
            if(coupleConfig === undefined || !coupleConfig.isFusion() || this.getConfig().isCoupleLabel()) {
                this.$el.show();
            }
        }

        /*this.$el.css({
            width: loc.fieldWidth,
            height: loc.height,
            top: loc.top,
            'margin-left': loc.fieldLeft
        });*/
    }

    getFieldPositionModifier(loc, left){
        let fieldLeft : number; 
        let leftValue : number;
        let fieldLeftHasPerc : boolean = typeof loc.fieldLeft === 'string' && loc.fieldLeft.indexOf('%') > -1;

        if(fieldLeftHasPerc) {
            fieldLeft = parseInt(loc.fieldLeft.substring(0, loc.fieldLeft.length-1));
        } else {
            fieldLeft = parseInt(loc.fieldLeft);
        }
        
        if(typeof left === 'string' && left.indexOf('%') > -1) {
            leftValue = parseInt(left.substring(0, left.length-1));
        } else {
            leftValue = parseInt(left);
        }

        var marLeft;
        if(fieldLeftHasPerc){
            marLeft = (fieldLeft - left)/ (fieldLeft + parseInt(loc.fieldWidth.substring(0, loc.fieldWidth.length-1)) - leftValue) * 100 + '%';
        }else{
            marLeft = fieldLeft-leftValue;
        }
        var fWidth;

        if(fieldLeftHasPerc){
            fWidth = parseInt(loc.fieldWidth.substring(0, loc.fieldWidth.length-1)) / (fieldLeft +
                            parseInt(loc.fieldWidth.substring(0, loc.fieldWidth.length-1)) -
                             leftValue) * 100 + '%';
        }else{
            fWidth = loc.fieldWidth;
        }

        return {
            width: fWidth,
            height: loc.fieldHeight,
            'margin-top' : ( loc.height - loc.fieldHeight ) / 2,
            'margin-left': marLeft
        };
    }

    getLabelElement (){
        return this.$el.find('label');
    }

    getFieldElement (){
        return this.$el.find('.form-control');
    }

    getFocusableElement(){
        return this.$el.find('input');
    }

    setDominantColor(color){

    }

    public getConfig(): typeof Field {
        return this.fieldConfig;
    }

    public getState(): typeof FieldInstance {
        return this.fieldState;
    }

    public getLocationProperties(): FieldLocationProperties {
        return this.locationProperties;
    }

    public hasInnerValues(): boolean {
        return false;
    }

    public getInnerValues(): { [dfId: string]: string} {
        return {};
    }

    public validate(): boolean {
        let value = this.getValue();
        let fieldImportance = this.fieldState.getImportance();
        let datafieldImportance = this.fieldState.getLinkedDataField().getImportance();
        let isCouple = this.fieldConfig.getLinkedCouple() !== undefined;
        let isLabelCoupleDatafield = isCouple && this.fieldConfig.getLinkedCouple().getLabelField().getDatafieldId() === this.fieldConfig.getDatafieldId();
        if(isLabelCoupleDatafield) { return true; }
        let valueMandatory = this.fieldState.isEnabled() && this.fieldState.isVisible(this.displayMode)
            && (fieldImportance !== undefined ? fieldImportance === '1' : datafieldImportance === '1');
        let valueTooLong = false;
        if(this.getConfig().getLinkedDataField().getMaxLength()) {
            valueTooLong = value ? value.length > parseInt(this.getConfig().getLinkedDataField().getMaxLength()) : false;
        }
        let isInvalid: boolean = (valueMandatory && !value) || valueTooLong;
        this.$el.toggleClass('field-invalid', isInvalid);
        this.fieldState.setValidityLevel(isInvalid ? FieldValidityLevel.INVALID : FieldValidityLevel.VALID);
        return !isInvalid;
    }

    public focus() {
        this.$el.find('input').focus();
    }

    protected addFieldHelp(): void {
        let help = this.getConfig().getConfig().help;
        if(help !== undefined && help !== '') {
            let helpIcon = this.$el.find('.help-icon');
            helpIcon.show();
            helpIcon.attr('title',help);
            this.$el.css('padding-right','42px');
            helpIcon.addClass('no-autoinit');
            (helpIcon as any).tooltip();
        }
    }

    public getId(): string {
        return this.getConfig().getId();
    }

    public isReadOnly(): boolean {
        return this.getConfig().isReadOnly();
    }

    public getLabel(): string {
        return this.getConfig().getLabel();
    }
}

export default FieldView;
