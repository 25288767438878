'use strict';

import FieldView from '../Field';

class TextAreaField extends FieldView {

    onDOMUpdated () {
        super.onDOMUpdated();
        //Resizing textarea so that it fills its content
        this.$el.css({
            'height': 'auto',
            'margin-bottom': '0.8rem'
        });
    }

    getFocusableElement(){
        return this.$el.find('textarea');
    }

    getFieldElement(){
        return this.$el.find('textarea');
    }
}

export default TextAreaField;
