'use strict';

import FieldView from '../Field';

class LinkField extends FieldView {
    constructor(options: any) {
        super(options);

        if(this.fieldState.isReadOnly()) {
            this.fieldState.setEnabled(false);
        }

        let pipeIndex = this.fieldState.getLabel().indexOf('|');
        if( pipeIndex >= 0) {
            this.fieldState.setLabel(this.fieldState.getLabel().substring(pipeIndex + 1).trim());
        }
    }

    getFieldValue(): boolean | string | number | string[] {
        return '';
    }

    getlabelElement(){
        return this.getFieldElement();
    }

    getFieldElement(){
        return this.$el.find('a');
    }

    getFieldPositionModifier(loc, left){
        return {
            width: loc.fieldWidth,
            height: loc.fieldHeight,
            'margin-left': left === '0px'? 10 : 0,
            'margin-top' : ( loc.height - loc.fieldHeight ) / 2
        };
    }

    render() {
        super.render();
        this.$el.find('span').css('color', '#7EB953');
    }

    onDOMUpdated () {
        super.onDOMUpdated();
        let me = this;
        this.getFieldElement().on('click', function() {
            me.trigger('linkclicked', me.fieldConfig);
        });
    }
}

export default LinkField;
