module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="portrait ' +
__e( valid ? '' : 'invalid' ) +
'">\n    <i class="fas fa-' +
__e( member.getTitre() === '1' ? 'female' : member.getTitre() === '3' ? 'male' : 'user' ) +
'" aria-hidden="true"></i>\n    ';
 if(member.canBeRemoved() === true) { ;
__p += '\n        <i class="remove-member">X</i>\n    ';
 } ;
__p += '\n</div>\n';
 if(member.getRole() !== '' || (member.getTitre() && member.getTitre() !== '')) { ;
__p += '\n    <div class="member-role">' +
__e( member.getRolesCombo().getElement(member.getRole()) != null ? member.getRolesCombo().getElement(member.getRole()).getLabel() : "" ) +
'</div>\n    <div class="member-title">' +
__e( member.getLabel() ) +
'</div>\n';
 } else { ;
__p += '\n    <div class="member-title">' +
((__t = ( add_label )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
}