module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<button type="button" class="fieldButton btn btn-default" aria-label="Left Align" data-id="' +
__e( fieldState.getId() ) +
' " ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
' >' +
__e( fieldState.getLabel() ) +
'<i class="fas fa-cloud-download" style="margin-left: 10px; font-size: 15px;"></i></button>';

}
return __p
}