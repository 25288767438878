'use strict';

import { PortalView } from '../PortalView';
import { Combo } from '@sai/configuration';
import { Context } from '@sai/protocol';
import FamilyMemberView from './FamilyMemberView';
import I18NUtils from '../../utils/I18NUtils';
import RecordView from '../record/Record';

class FamilyView extends PortalView {
    private members: Array<FamilyMemberView>;
    private maxCount: number;
    private addMoreElement: JQuery<HTMLElement>;
    private memberListEl: JQuery<HTMLElement>;
    private notEnoughMembers: JQuery<HTMLElement>;
    private invalidMembers: JQuery<HTMLElement>;
    private rolesCombo: typeof Combo;
    private lang: string;

    constructor(options: { maxCount: number, rolesCombo: typeof Combo, [key: string]: any}) {
        super(options);
        this.members = [];
        this.maxCount = options.maxCount;
        this.rolesCombo = options.rolesCombo;
        this.template = require('../../templates/family/Family.ejs');
        this.lang = options.lang;
    }

    public render(): void {
        this.$el.addClass('family-view');
        this.$el.html(this.template({ family: this }));
        this.memberListEl = this.$el.children('.members-list');
        {
            this.addMoreElement = $('<div></div>').appendTo(this.memberListEl);
            let fakeMember = new FamilyMemberView({ el: this.addMoreElement, label: 'Nouveau', role: '', combo: this.rolesCombo, allowRemoval: false, values: null, lang:this.lang });
            fakeMember.render();
            this.addMoreElement.addClass('add-member');
            this.addMoreElement.on('click', () => {
                this.trigger('family.newmember');
            })
        }
        {
            this.notEnoughMembers = $('<div></div>').appendTo(this.$el);
            this.notEnoughMembers.addClass('validation-failure');
            let errorLabel = I18NUtils.getText('missing_intervenant',this.lang, {});
            errorLabel = errorLabel.replace('%d', ''+(this.maxCount === -1 ? 1 : this.maxCount));
            this.notEnoughMembers.text(errorLabel);
            this.notEnoughMembers.hide();

            this.invalidMembers = $('<div></div>').appendTo(this.$el);
            this.invalidMembers.addClass('validation-failure');
            let errorInvalidMemberLabel = I18NUtils.getText('invalid_intervenant',this.lang, {});
            this.invalidMembers.text(errorInvalidMemberLabel);
            this.invalidMembers.hide();
        }
    }

    public getMembers(): Array<FamilyMemberView> {
        return this.members;
    }

    public createNewMember(values: typeof Context, recordView:RecordView, valid?: boolean) {
        let strVals = this.getFieldStrValues(values);
        let label = strVals.NOM + ' ' + strVals.PRENOM;
        let role = strVals.ROLE;
        let titre = strVals.TITRE;
        let newMemberEl = $('<div></div>').insertBefore(this.addMoreElement);
        let newMember = new FamilyMemberView({
            el: newMemberEl,
            values: values,
            label: label,
            role: role,
            titre: titre,
            combo: this.rolesCombo,
            allowRemoval: true,
            lang: this.lang,
            valid: valid
        });
        this.addMember(newMember);
        this.addEventsListeners(newMember.$el, newMember, recordView);
    }

    private getFieldStrValues(values: typeof Context): {[key:string]: string} {
        var fields = values.attributes.screen;
        let strVals = {};
        for (var key in fields) {
            strVals[fields[key].key] = fields[key].value;
        }
        return strVals;
    }

    public getMember(id: number): FamilyMemberView {
        for (let key = this.members.length - 1; key >= 0; key--) {
            let member = this.members[key];
            if (member.getId() === id) {
                return member;
            }
        }
    }

    public updateMember(id: number, values: typeof Context, recordView:RecordView): void {
        for (let key = this.members.length - 1; key >= 0; key--) {
            let member = this.members[key];
            if (member.getId() === id) {
                member.setValues(values);
                member.setValid();
                member.render();
                this.addEventsListeners(member.$el, member, recordView);
                break;
            }
        }
    }

    private addEventsListeners(element, familyMember:FamilyMemberView, recordView:RecordView):void {
        element.on('click', () => {
            this.trigger('family.modifymember', familyMember, recordView);
        });
        element.find('.remove-member').on('click', () => {
            this.deleteMember(familyMember.getId());
            this.trigger('family.removedmember', familyMember.getId(), recordView);
        });
    }

    /**
     * Removes the requested member from the family
     */
    public deleteMember(id: number): void {
        for(let i in this.members) {
            let member = this.members[i];
            let memberIndex:number = parseInt(i);
            if(member.getId() === id) {
                this.members[memberIndex].$el.remove();
                this.members.splice(memberIndex, 1);
            }
        }
        if(this.members.length < this.maxCount || this.maxCount === -1) {
            this.displayAddMore();
        }
    }

    public addMember(newMember: FamilyMemberView): void{
        this.members.push(newMember);
        newMember.render();
        if(this.maxCount === this.members.length) {
            this.hideAddMore();
        }
    }

    public displayAddMore(): void {
        this.addMoreElement.show();
    }

    public hideAddMore(): void {
        this.addMoreElement.hide();
    }

    public hasEnoughMembers(): boolean {
        return (this.members.length >= 1 && this.maxCount == -1) || this.maxCount === this.members.length;
    }

    /**
     * Returns true if the required conditions for family content are matched.
     * Displays an alert message if it's not the case.
     */
    public validate() : boolean {
        this.notEnoughMembers.hide();
        this.invalidMembers.hide();
        //The show is performed only if there is not enough members
        let hasEnoughtMbr = this.hasEnoughMembers();
        if(!hasEnoughtMbr) {
            this.notEnoughMembers.show();
            return false;
        }

        for(let member of this.members) {
            if(!member.isValid()) {
                this.invalidMembers.show();
                return false;
            }
        }
        return true;
    }

    /**
     * Returns the sums of all defined revenu from the family, 0 if
     * nothing is set or defined.
     */
    public getRevenuAmount(): number {
        let sum = 0;
        for(let key in this.members) {
            let mem = this.members[key];
            let allValues = mem.getValues().attributes.screen;
            for(let fId in allValues) {
                let field = allValues[fId];
                if(field.key === 'MONTANT') {
                    const montant = parseInt(field.value);
                    sum = sum + (isNaN(montant) ? 0 : montant);
                } else if(field.key === 'HIDDEN_REVENU' && field.value === '1') {
                    return -1;
                }
            }
        }
        return sum;
    }
}

export default FamilyView;
