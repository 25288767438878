module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="portrait">\n    <i class="fas fa-calendar-alt" aria-hidden="true"></i>\n    ';
 if(request.canBeRemoved() === true) { ;
__p += '\n        <i class="remove-request">X</i>\n    ';
 } ;
__p += '\n</div>\n';
 if(request.canBeRemoved() === true) { ;
__p += '\n    <div class="request-title">' +
__e( request.getLabel() ) +
'</div>\n';
 } else { ;
__p += '\n    <div class="request-title">' +
((__t = ( add_label )) == null ? '' : __t) +
'</div>\n';
 } ;


}
return __p
}