'use strict';

import FieldView from '../Field';

class CheckBoxField extends FieldView {
    private checked: boolean;
    private dominantColor: string;
    /**
     * Initialize the checkbox based on the field properties.
     *
     * @param {type} options
     * @returns {undefined}
     */
    constructor(options: any) {
        super(options);

        this.checked = this.fieldState.getValue() === '1';

        if(this.fieldState.isReadOnly()) {
            this.fieldState.setEnabled(false);
        }
        this.dominantColor = options.dominantColor;
    }

    render() {
        super.render();
        if(!this.fieldState.isReadOnly()) {
            this.$el.find('.checkbox').on('click', this.handleClick.bind(this));
        }
        if(this.dominantColor) {
            this.$el.find('.cr-icon').css('color', this.dominantColor);
        }
    }

    handleClick(e) {
        if($(e.target).is('label')) {
            this.$el.find('input').prop('checked', this.$el.find('input').is(':checked') ? false : true);
        }
        this.performChange(false, e, false);
    }

    getFieldValue(): boolean | string | number | string[] {
        return this.$el.find('.changeableField').is(':checked');
    }

    parseValue (value){
        super.parseValue(value);
        if(value === '1' || value === 'true' || value === 'on' || value === true){
            return '1';
        }else{
            return '0';
        }
    }

    setModelValue(value) {
        super.setModelValue(value);
        this.checked = this.fieldState.getValue() === '1';
        if(this.$el) {
            this.getFieldElement().prop('checked', this.checked);
        }
    }

    getlabelElement(){
        return this.$el.find('.checkbox');
    }

    getFieldElement(){
        return this.$el.find('.checkbox');
    }

    getFieldPositionModifier(loc, left){
        return {
            width: loc.fieldWidth,
            height: loc.fieldHeight,
            'margin-left': left === '0px'? 10 : 0,
            'margin-top' : ( loc.height - loc.fieldHeight ) / 2
        };
    }

    setDominantColor(color) {
        this.dominantColor = color;
        this.$el.find('.cr-icon').css('color', this.dominantColor);
    }
}

export default CheckBoxField;
