class ImageUtils {
    private static _instance: ImageUtils;

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    resizeImage(file: File, targetSize) : Promise<File> {
        return new Promise((accept, reject) => {
            this.readFile(file)
            .then((fileResult) => {
                if((fileResult as string).startsWith('data:image/')) {
                    let parts = (fileResult as string).split(';');
                    let mimeType = parts[0].split(':')[1];
                    let imageData = parts[1].split(',')[1];

                    let img = atob(imageData);
                    if(img.length > targetSize) {
                        this.loadImage(fileResult as string)
                            .then((image: any) => {
                                let ratio = img.length / targetSize;
                                let targetWidth = image.width / Math.sqrt(ratio);
                                let targetHeight = image.height / Math.sqrt(ratio);

                                let canvas = document.createElement('canvas');
                                canvas.width = targetWidth;
                                canvas.height = targetHeight;
                                let imageContext = canvas.getContext('2d');
                                imageContext.drawImage(image.data, 0, 0, targetWidth, targetHeight);
                                canvas.toBlob((blob) => {
                                    let img: any = blob;
                                    img.lastModifiedDate = new Date();
                                    img.name = file.name;
                                    accept(img as File);
                                }, mimeType)
                            })
                            .catch(reject);
                    } else {
                        accept(file);
                    }
                } else {
                    accept(file);
                }
            })
            .catch(reject);
        })
    }

    loadImage(imgData: string) {
        return new Promise((accept) => {
            let img = new Image();
            img.onload = () => {
                accept({
                    width: img.width,
                    height: img.height,
                    data: img
                });
            }
            img.src = imgData;
        });
    }

    readFile(file): Promise<string|ArrayBuffer> {
        return new Promise((accept) => {
            const filereader = new FileReader();
            filereader.onload = function() {
                accept(filereader.result);
            }
            filereader.readAsDataURL(file);
        });
    }
}

const singleInstance = ImageUtils.Instance;
export default singleInstance;