'use strict';

import _ from 'lodash';
const moment = require('moment');

class DatePickerUtils {
    //Singleton definition
    private static _instance: DatePickerUtils;
    private debug: boolean;

    private constructor() {
        this.debug = true;
        (jQuery.fn as any).datetimepicker.Constructor.prototype._origSetValue = (jQuery.fn as any).datetimepicker.Constructor.prototype._setValue;
        (jQuery.fn as any).datetimepicker.Constructor.prototype._setValue = function _setValue(targetMoment, index) {
            var oldDate = this.unset ? null : this._dates[index];
            var outpValue = '';
            if (!targetMoment && (!this._options.allowMultidate || this._dates.length === 1)) {
                this.unset = true;
                this._dates = [this.getMoment()];
                this._datesFormatted = [];
                this._viewDate = this.getMoment().locale(this._options.locale).clone();
                if (this.input !== undefined) {
                    this.input.val('');
                    this.input.trigger('input');
                }
                this._element.data('date', outpValue);
                this._notifyEvent({
                    type: (jQuery.fn as any).datetimepicker.Constructor.Event.CHANGE,
                    date: false,
                    oldDate: oldDate
                });
                this._update();
            }
            else
            {
                this._origSetValue(targetMoment, index);
            }
        };
    }

    static get Instance() {
        return this._instance || (this._instance = new this());
    }

    buildDatePicker($el: any, options?: {[key: string] : any}) {
        let lang = $('html').attr('lang').toLowerCase() || 'fr';
        let pickerOpts = _.merge(options ||{}, {
            format: 'DD/MM/YYYY',
            locale: lang,
            buttons: {
                showToday: true,
                showClear: true,
                showClose: true
            },
            icons: {
                today: 'fa fa-calendar',
            },
            debug: this.debug,
            useCurrent: false // Prevent a change event when the picker is opened
                              // Triggering a change on open sometimes generate an error
                              // because the page is not fully loaded yet
        });
        ($el as any).datetimepicker(pickerOpts);
        $el.off('show.datetimepicker');
        $el.on('show.datetimepicker', () => {
            let vDate = $el.datetimepicker('date');
            let cDate = vDate ? vDate : ($el.datetimepickerOpts && $el.datetimepickerOpts.defaultDate ? moment(pickerOpts.defaultDate) : moment());
            this.updateDate($el, cDate, pickerOpts.locale);
        });
        $el.on('hide.datetimepicker', (ev) => {
            this.updateDate($el, ev.date, pickerOpts.locale);
        });
    }

    updateDate($el: any, cDate: any, locale: string) {
        //We need to update the picker value based on its current value
        let outputDate = $('<div></div>').addClass('date-display');
        $('<span></span>').addClass('year-display').text(!cDate ? '' : cDate.locale(locale).format('YYYY')).appendTo(outputDate);
        $('<span></span>').addClass('day-display').text(!cDate ? '' : cDate.locale(locale).format('ddd D MMM')).appendTo(outputDate);
        $el.find('.list-unstyled').find('.date-display').remove();
        outputDate.appendTo($el.find('.list-unstyled'));
        if(cDate && !$('.bootstrap-datetimepicker-widget').is(':visible')) {
            $el.trigger('change');
        }
    }

    buildTimePicker($el: any, options?: { [key: string]: any }) {
        let lang = $('html').attr('lang').toLowerCase() || 'fr';
        let pickerOpts = _.merge(options || {}, {
            format: "HH:mm",
            locale: lang,
            buttons: {
                showClear: true,
                showClose: true
            },
            debug: this.debug
        });
        ($el as any).datetimepicker(pickerOpts);
        $el.off('show.datetimepicker');
        $el.on('show.datetimepicker', () => {
            let vDate = $el.datetimepicker('date');
            let cDate = vDate ? vDate : ($el.datetimepickerOpts.defaultDate ? moment(pickerOpts.defaultDate) : moment());
            this.updateTime($el, cDate, pickerOpts.locale);
        });
        $el.on('hide.datetimepicker', (ev) => {
            this.updateTime($el, ev.date, pickerOpts.locale);
        });
    }

    updateTime($el: any, cDate: any, locale: string) {
        //We need to update the picker value based on its current value
        let outputDate = $('<div></div>').addClass('date-display');
        $('<span></span>').addClass('day-display').text(!cDate ? '' : cDate.locale(locale).format('HH:mm')).appendTo(outputDate);
        $el.find('.list-unstyled').find('.date-display').remove();
        outputDate.appendTo($el.find('.list-unstyled'));
        if(cDate && !$('.bootstrap-datetimepicker-widget').is(':visible')) {
            $el.trigger('change');
        }
    }
}

const singleInstance = DatePickerUtils.Instance;
export default singleInstance;