'use strict';

import FieldView from '../views/record/Field';

import GroupView from '../views/record/Group';

const F_BF = require( '../views/record/subfields/Button');
const F_CBF = require( '../views/record/subfields/CheckBox');
const F_LF = require( '../views/record/subfields/Link');
const F_CF = require( '../views/record/subfields/Couple');

const F_DF = require( '../views/record/subfields/Date');
const F_DTF = require( '../views/record/subfields/DateTime');
const F_DTTF = require( '../views/record/subfields/Time');

//const F_GIF = require( '../views/record/subfields/GedImage');
//const F_HF = require( '../views/record/subfields/Hidden');
const F_LSF = require( '../views/record/subfields/ListEnum');
//const F_GDO = require( '../views/record/subfields/GedDocument');

const F_NF = require( '../views/record/subfields/Number');
const F_SF = require( '../views/record/subfields/Enum');
const F_TF = require( '../views/record/subfields/Text');
const F_FF = require( '../views/record/subfields/Filter');
const F_TAF = require( '../views/record/subfields/TextArea');
const F_GCF = require( '../views/record/subfields/GPSCoordinates');
const F_FLF = require( '../views/record/subfields/Float');
const F_EIF = require( '../views/record/subfields/EnumIcon');

const F_PAS = require( '../views/record/subfields/Password');
const F_INT = require( '../views/record/subfields/Integer');
const F_PHO = require( '../views/record/subfields/Phone');
const F_EMA = require( '../views/record/subfields/Email');
const F_LCF = require( '../views/record/subfields/ListCouple');
const F_FIF = require( '../views/record/subfields/File');

/*import F_SYF from '../views/record/subfields/System';
import F_PS from '../views/record/subfields/PrintSettings';*/

import FieldUnit from './FieldUnit';
//import { DomainContext } from '../parametrage/DomainContext';
//import { Field } from '../parametrage/structures/Field';

export class FieldFactory {

    private static fieldNameToObject(type : string) {
        switch(type) {
            case 'Button'          : return F_BF;
            case 'Link'            : return F_LF;
            case 'Checkbox'        : return F_CBF;
            case 'Couple'          : return F_CF;
            case 'Date'            : return F_DF;
            case 'DateTime'        : return F_DTF;
            case 'Time'            : return F_DTTF;
            case 'GEDImage'        : return F_TF;
            //case 'GEDDocument'     : return F_GDO;
            //case 'Hidden'          : return F_HF;
            case 'ListEnum'        : return F_LSF;
            case 'Financial'       : return F_NF;
            case 'Enum'            : return F_SF;
            case 'Text'            : return F_TF;
            case 'MultiLineString' : return F_TAF;
            case 'Password'        : return F_PAS;
            case 'Integer'         : return F_INT;
            case 'Phone'           : return F_PHO;
            case 'ListCouple'      : return F_LCF;
            //case 'SystemField'     : return F_SYF;
            case 'Filter'          : return F_FF;
            case 'GPSCoordinates'  : return F_GCF;
            //case 'PrintSettings'   : return F_PS;
            case 'Float'           : return F_FLF;
            case 'Email'           : return F_EMA;
            case 'EnumIcon'        : return F_EIF;
            case 'File'            : return F_FIF;
        };
        return function() {console.error('invalid type '+type);};
    }

    public static buildField<T extends FieldView>(fieldConfig : any, displayMode, domainContext: any, visible?:boolean, fileUploadCallback?: any) : T {
        let type = fieldConfig.getLinkedDataField() === undefined ? 'Text' : fieldConfig.getObjectTypeFromFormatPresentation();
        var FieldDescriptor: any = this.fieldNameToObject(type).default;
        return new FieldDescriptor({
            config: fieldConfig,
            displayMode: displayMode,
            domainContext: domainContext,
            visible: visible !== undefined ? visible: true,
            fileUploadCallback: fileUploadCallback
        });
    }

    public static locateFieldInGroup(groupView : GroupView, fields) : void {
        var rowMap = {};
        var autoMap = {};

        //Handling multiple fields on rows
        //Setting up the top and height position$
        let displayMode = groupView.getDisplayMode();
        for(var fIndex in fields){
            var currentField:FieldView = fields[fIndex];
            var currentPosition = currentField.getState().getPosition(displayMode);
            if(!currentPosition.visible || currentPosition.row == -99) {
                continue;
            }
            var fieldUnits = FieldUnit[displayMode];
            if(currentPosition.row > 0){
                if(!rowMap[currentPosition.row]){
                    rowMap[currentPosition.row] = [];
                    //Number of auto items
                    autoMap[currentPosition.row] = {
                        count: 0, // Number of auto in the row
                        fixedSpace: 0 // Total space already used in %
                    };
                }

                var fieldWidth = currentPosition.width;
                var fieldHeight = currentPosition.height;
                var value;
                if(/^\d+$/.test(fieldWidth)){
                    //Direct Unit
                    //Computing percentage of the screen taken by the fixedSpace
                    var groupWidth = groupView.$el.width();
                    value = fieldWidth * fieldUnits.col;
                    autoMap[currentPosition.row].fixedSpace = autoMap[currentPosition.row].fixedSpace + value;
                    currentField.getLocationProperties().fieldWidth = value+'px';
                }else if(fieldWidth.toLowerCase() === 'auto'){
                    //we need an extra iteration to resolve
                    autoMap[currentPosition.row].count = autoMap[currentPosition.row].count + 1;
                }else{
                    //direct percentage
                    currentField.getLocationProperties().fieldWidth = fieldWidth;
                    value = parseInt(fieldWidth.substring(0,fieldWidth.length - 1));
                    autoMap[currentPosition.row].fixedSpace = autoMap[currentPosition.row].fixedSpace + value;
                }

                let borders = fieldUnits.height - fieldUnits.fieldHeight;
                currentField.getLocationProperties().labelVisible = currentPosition.labelVisible;
                currentField.getLocationProperties().height = borders + fieldHeight * fieldUnits.fieldHeight;
                currentField.getLocationProperties().fieldHeight = fieldHeight * fieldUnits.fieldHeight;
                currentField.getLocationProperties().top = ((currentPosition.row -1) * fieldUnits.height) + fieldUnits.height/8; // As group will have an extra fieldHeight/4
                rowMap[currentPosition.row].push(currentField);
            }
        }
        //Setting up the left and width position
        for(var rowIdx in rowMap){
            var currentRow = rowMap[rowIdx];
            var currentMargin = 0;
            for(var fieldIdx in currentRow){
                var currentField: FieldView = currentRow[fieldIdx];
                var fieldUnt = FieldUnit[displayMode];
                var currentFieldPosition = currentField.getState().getPosition(displayMode);
                var fieldCol = currentFieldPosition.col;
                var labelCol = currentFieldPosition.labelColumn;

                if(/^\d+$/.test(fieldCol)){
                    // -1 is due to the fact that the parametrage always specifies 1 as the first column
                    currentField.getLocationProperties().fieldLeft = (fieldCol -1) * fieldUnt.col + 'px';
                }else if(fieldCol.toLowerCase() === 'auto'){
                    currentField.getLocationProperties().fieldLeft = currentMargin + '%';
                }else {
                    currentField.getLocationProperties().fieldLeft = fieldCol;
                }

                if(/^\d+$/.test(labelCol)){
                    // -1 is due to the fact that the parametrage always specifies 1 as the first column
                    currentField.getLocationProperties().labelLeft = (labelCol -1) * fieldUnt.col + 'px';
                }else {
                    currentField.getLocationProperties().labelLeft = labelCol;
                }

                if(currentField.getLocationProperties().fieldWidth === undefined){
                    if(autoMap[rowIdx].width === undefined){
                        autoMap[rowIdx].width = (100 - autoMap[rowIdx].fixedSpace) / autoMap[rowIdx].count + '%';
                    }
                    //Auto field
                    currentField.getLocationProperties().fieldWidth = autoMap[rowIdx].width;
                }
                currentMargin = currentMargin + parseInt(currentField.getLocationProperties().fieldWidth.substring(0,currentField.getLocationProperties().fieldWidth.length - 1));
                
                if(currentField.getConfig().isCouple() && currentField.fieldConfig.getLinkedCouple().isFusion()) {
                    this.handleFusionCouple(currentField,fields);
                }
            }
        }

        //Moving the screen into proper position based on previously computed
        for(var rowId in fields){
            var currentRowField: FieldView = fields[rowId];
            var currentRowFieldPosition = currentRowField.getState().getPosition(displayMode);
            if(currentRowFieldPosition.row > 0){
                fields[rowId].renderPosition();
            }
        }
    };

    public static locateGroupinPanel(panel, groupView: GroupView, mode) : {left?: any, top?: any, width?: any, height?: any} {
        let position = groupView.getConfig().getPosition(mode);

        if(mode === 'phone'){
            return {
                left: (position.column*FieldUnit[mode].col),
                width: '100%'
            };
        }else{
            let positionHeigh = parseInt(position.height);
            let heightToSet = positionHeigh * FieldUnit[mode].height + FieldUnit[mode].height*3/4;
            heightToSet = (positionHeigh+1) * FieldUnit[mode].height - 3;
        }
    };

    private static handleFusionCouple(currentField: FieldView, fields: any) {
        let couple = currentField.fieldConfig.getLinkedCouple();
        let childrenIds = [];
        for(let child in couple.getFields()) {
            childrenIds.push(couple.getFields()[child].getId());
        }
        
        let minLeft;
        let currentWidth = 0;
        let unitType;
        for(let fieldIdx in childrenIds) {
            let coupleFieldView = fields[childrenIds[fieldIdx]];
            let pos = coupleFieldView.getLocationProperties();
            unitType = pos.fieldWidth.indexOf('%') > 0 ? '%' : 'px';
            if(pos && pos.fieldLeft) {
                if(minLeft) {
                    let currentLeft = this.getNumberValue(pos.fieldLeft);
                    if(currentLeft < minLeft) {
                        currentWidth = minLeft - currentLeft + currentWidth;
                        minLeft = currentLeft;
                    } else {
                        currentWidth = currentLeft - minLeft + this.getNumberValue(pos.fieldWidth);
                    }
                } else {
                    minLeft = this.getNumberValue(pos.fieldLeft);
                    currentWidth = this.getNumberValue(pos.fieldWidth);
                }
            } else {
                return;
            }
        }

        for(let fieldIdx in childrenIds) {
            let coupleFieldView = fields[childrenIds[fieldIdx]];
            let pos = coupleFieldView.getLocationProperties();
            pos.fieldWidth = currentWidth + unitType;
            pos.fieldLeft = minLeft + unitType;
        }
    }

    private static getNumberValue(strNum: string) : number {
        let regex = /(\d+)[p%]/;
        let match = regex.exec(strNum);
        return parseInt(match[1]);
    }
}

//export default FieldFactory;