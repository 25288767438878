module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<label for="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'">' +
__e( fieldState.getLabel() ) +
'' +
__e( fieldState.isRequired(displayMode)?'*':'' ) +
'</label>\n<div class="input-group date" id="' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" data-target-input="nearest">\n    <input type="text" class="form-control datetimepicker-input changeableField" data-target="#' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" value="' +
__e( fieldState.getValue() !== '' ? moment(fieldState.getValue(), 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY'):'' ) +
'" ' +
__e( fieldState.isEnabled() ? '' : 'disabled') +
'/>\n    <div class="input-group-append" data-target="#' +
__e( fieldState.getInternal() ) +
'-' +
__e( fieldState.getId() ) +
'" data-toggle="datetimepicker">\n        <div class="input-group-text"><i class="fa fa-calendar"></i></div>\n    </div>\n</div>\n<span class="help-icon" style="display: none;" data-toggle="tooltip" data-html="true"><img src="/images/help-icon.png"></span>';

}
return __p
}